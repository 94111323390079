<template>
  <basicLayout>
    <!-- https://bootstrapious.com/p/bootstrap-sidebar -->
    <h5>DASHBOARD</h5>
    <div>
      <div class="row m-1">
        <div class="col-12">
          <Ticker />
        </div>
      </div>
      <div class="row m-1">
        <div class="col-12">
          <div class="card-group">
            <Logs></Logs>
            <OrdersLog></OrdersLog>
          </div>
        </div>
      </div>
      <div class="row m-1">
        <div class="col-12">
          <div class="card-deck mb-3">
            <div class="card mb-4 shadow-sm">
              <div class="card-header">
                <h5 class="my-0 font-weight-normal">Online Sales</h5>
              </div>
              <div class="card-body">
                <onlineorderschart></onlineorderschart>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-5">
          <div class="card mb-4 shadow-sm">
            <div class="card-header">
              <h5 class="my-0 font-weight-normal">Bulk Product Uploads</h5>
            </div>
            <div class="card-body">
              <div class="custom-file">
                <input
                  type="file"
                  class="custom-file-input"
                  id="customFile"
                  @change="upload"
                />
                <label class="custom-file-label" for="customFile"
                  >Choose file...</label
                >
              </div>
            </div>
            <button @click="prevailing" type="button" class="btn btn-primary">
              prevailing
            </button>
            <button @click="runTables" type="button" class="btn btn-primary">
              force_bigcommerce_sync
            </button>
            <button
              @click="products_hide"
              type="button"
              class="btn btn-primary"
            >
              products_hide
            </button>
            <button
              @click="generate_price_list"
              type="button"
              class="btn btn-primary"
            >
              generate price list
            </button>
            <!-- <button @click="bc_prices" type="button" class="btn btn-primary">bc_prices</button> -->
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { fb, storage } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import Logs from "@/components/Logs";
import OrdersLog from "@/components/OrdersLog";
import Ticker from "@/components/Ticker";
import onlineorderschart from "@/components/OnlineOrdersChart";

// import "jsprintmanager";

export default {
  name: "dashboard",
  data() {
    return {
      orders: [],
      logs: [],
      log_type: "all",
      printers: []
    };
  },
  components: { basicLayout, Logs, OrdersLog, Ticker, onlineorderschart },

  methods: {
    upload(event) {
      console.log("uploading...");
      const file = event.target.files[0];
      const storageRef = storage.ref(file.name);
      storageRef.put(file);
    },

    generate_price_list() {
      console.log("generate_price_list");

      let name = {
        bc_customer_id: 516,
        group: "GHARRIS",
        email2: "elaine@gordonharris.co.nz",
        colour: "0",
        name: "GORDON HARRIS LIMITED",
        custom2: "",
        category2: "",
        category3: "",
        bc_customer_group_id: 1878,
        has_rule: true,
        code: "GHARRIS",
        custom4: "PB",
        last_update: { seconds: 1628543710, nanoseconds: 489000000 },
        email: "accountspayable@gordonharris.co.nz",
        productpricing: "E",
        custom3: "",
        custom8: "YES",
        custom7: "174.7752027",
        custom5: "NORTH ISLAND",
        custom1: "",
        custom6: "-36.869624",
        category1: "GHARRIS"
      };
      const generate_price_list = fb.httpsCallable("generate_price_list");
      generate_price_list(name).then(result => {
        console.log(JSON.stringify(result));
      });
    },

    prevailing() {
      console.log("bc price rules");
      const prevailing = fb.httpsCallable("prevailing");
      prevailing().then(result => {
        console.log(JSON.stringify(result));
      });
    },
    runTables() {
      console.log("bc price rules");
      const force_bigcommerce_sync = fb.httpsCallable("force_bigcommerce_sync");
      force_bigcommerce_sync().then(result => {
        console.log(JSON.stringify(result));
      });
    },
    products_hide() {
      console.log("bc price rules");
      const products_hide = fb.httpsCallable("products_hide");
      products_hide().then(result => {
        console.log(JSON.stringify(result));
      });
    },
    log_class(log) {
      if (log.type == "info") return "text-secondary";
      if (log.type == "error") return "text-danger";
      if (log.type == "moneyworks") return "text-primary";
    }
  },
  computed: {}
};
</script>
<style scoped></style>