import Vue from 'vue'
import Router from 'vue-router'
import { auth, db } from "@/firebaseConfig";

import Admin from './components/Admin.vue'

import Dashboard from './components/Dashboard.vue'
import OrdersOnline from './components/OrdersOnline.vue'
import OrdersApi from './components/OrdersApi.vue'
import OnlineOrdersChart from './components/OnlineOrdersChart.vue'
import Rules from './components/Rules.vue'
import Customers from './components/Customers.vue'
import CustomerEdit from './components/CustomerEdit.vue'
import Products from './components/Products.vue'
import ProductsSearch from './components/ProductsSearch.vue'
import ProductEdit from './components/ProductEdit.vue'
import Groups from './components/Groups.vue'
import PriceList from './components/PriceList.vue'
import Receipts from './components/Receipts.vue'
import ReceiptCreate from './components/ReceiptCreate.vue'
import ReceiptEdit from './components/ReceiptEdit.vue'
import EmailLists from './components/EmailLists.vue'
import EmailListEdit from './components/EmailListEdit.vue'
import FeedFields from './components/FeedFields.vue'
import Tracking from './components/Tracking.vue'
import Scan from './components/Scan.vue'
import Webhooks from './components/Webhooks.vue'
import OrderUpload from './components/OrderUpload.vue'
import EDI from './components/EDI.vue'
import Ftp from './components/Ftp.vue'
import Users from './components/users.vue'
import Login from './components/Login.vue'
import UserProfile from './components/userProfile.vue'
import UserCreate from './components/userCreate.vue'
import RuleEdit from './components/RuleEdit.vue'
import NameEdit from './components/NameEdit.vue'
import Docs from './components/Docs.vue'
import Kanban from './components/Kanban/TaskBoard.vue'


Vue.use(Router);

let router = new Router({
  mode: 'history',
  routes: [{
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/admin',
    name: 'Admin ',
    component: Admin,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders-online',
    name: 'OrdersOnline',
    component: OrdersOnline,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders-api',
    name: 'OrdersApi',
    component: OrdersApi,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/online-orders-chart',
    name: 'OnlineOrdersChart',
    component: OnlineOrdersChart,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/order-upload',
    name: 'OrderUpload',
    component: OrderUpload,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rules',
    name: 'Rules',
    component: Rules,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/customer-edit/:customer_id',
    name: 'CustomerEdit',
    component: CustomerEdit,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/products-search',
    name: 'ProductsSearch',
    component: ProductsSearch,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/product-edit/:product_id',
    name: 'ProductEdit',
    component: ProductEdit,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/groups',
    name: 'Groups',
    component: Groups,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pricelist',
    name: 'PriceList',
    component: PriceList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/receipts',
    name: 'Receipts',
    component: Receipts,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/receipt-create',
    name: 'ReceiptCreate',
    component: ReceiptCreate,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/receipt-edit/:receipt_id',
    name: 'ReceiptEdit',
    component: ReceiptEdit,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/scan',
    name: 'Scan',
    component: Scan,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/rule/:rule_id',
    name: 'RuleEdit',
    component: RuleEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/name/:name_code',
    name: 'NameEdit',
    component: NameEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/emaillists',
    name: 'EmailLists',
    component: EmailLists,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/emaillistedit/:email_list_id',
    name: 'EmailListEdit',
    component: EmailListEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/emaillistcreate',
    name: 'EmailListCreate',
    component: EmailListEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/feed-fields',
    name: 'FeedFields',
    component: FeedFields,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tracking',
    name: 'Tracking',
    component: Tracking,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/webhooks',
    name: 'Webhooks',
    component: Webhooks,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/edi',
    name: 'edi',
    component: EDI,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/ftp',
    name: 'ftp',
    component: Ftp,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },

  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/user-create',
    name: 'UserCreate',
    component: UserCreate,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/user/:id',
    name: 'UserProfile',
    component: UserProfile,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/kanban',
    name: 'Kanban',
    component: Kanban,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/docs',
    name: 'Docs',
    component: Docs,
    meta: {
      requiresAuth: true
    }
  }
  ]
})

router.beforeEach(async (to, from, next) => {

  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const requiresAdmin = to.matched.some(x => x.meta.requiresAdmin)
  const currentUser = auth.currentUser
  const userProfile = currentUser && await db.collection("users").doc(currentUser.uid).get()
  const isAdmin = userProfile && userProfile.data().role === "admin"

  if (!requiresAuth) {
    next()
  }
  else if (requiresAuth && !currentUser) {
    next({
      path: '/login'
    })
  } else if (requiresAuth && currentUser) {
    if (!requiresAdmin) {
      next()
    } else if (requiresAdmin && isAdmin) {
      next()
    } else if (requiresAdmin && !isAdmin) {
      next(false)
    }
  }
})

export default router;
