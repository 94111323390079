<template>
  <div class="tree-menu">
    <div class="label-wrapper" @click="toggleChildren">
      <div :style="indent" :class="labelClasses">
        <!-- <i class="fa fa-plus-square" aria-hidden="true"></i> -->
        <!-- <i v-if="nodes" :class="nodeClasses"></i> -->

        <!-- <plus-square-icon
          v-if="nodes && !showChildren"
          size="1.5x"
          class="custom-class"
        ></plus-square-icon>
        <minus-square-icon
          v-if="nodes && showChildren"
          size="1.5x"
          class="custom-class"
        ></minus-square-icon> -->

        &nbsp;
        <!-- <i v-if="folder" :class="folderClasses"></i> -->
        <folder-plus-icon
          v-if="folder && !showChildren"
          size="1.5x"
          class="custom-class"
        ></folder-plus-icon>
        <folder-minus-icon
          v-if="folder && showChildren"
          size="1.5x"
          class="custom-class"
        ></folder-minus-icon>
        <file-text-icon
          v-if="file"
          size="1.5x"
          class="custom-class"
        ></file-text-icon>

        {{ node.name }}
        <span v-if="node.children" class="badge badge-pill badge-secondary">{{
          node.children.length
        }}</span>
      </div>
    </div>
    <div>
      <div v-if="showChildren">
        <tree-menu
          v-for="node in nodes"
          :nodes="node.children"
          :node="node"
          :key="node.name"
          :depth="depth + 1"
        >
        </tree-menu>
      </div>
    </div>
  </div>
</template>
<script>
import {
  // PlusSquareIcon,
  // MinusSquareIcon,
  FolderPlusIcon,
  FolderMinusIcon,
  FileTextIcon,
} from "vue-feather-icons";
export default {
  props: ["label", "nodes", "node", "depth"],
  data() {
    return { showChildren: false };
  },
  name: "tree-menu",
  components: {
    // PlusSquareIcon,
    // MinusSquareIcon,
    FolderPlusIcon,
    FolderMinusIcon,
    FileTextIcon,
  },
  computed: {
    // nodeClasses() {
    //   return {
    //     "fa fa-plus-square": !this.showChildren,
    //     "far fa-minus-square": this.showChildren,
    //   };
    // },
    // folderClasses() {
    //   return {
    //     "far fa-folder": !this.showChildren,
    //     "far fa-folder-open": this.showChildren,
    //   };
    // },
    // fileClasses() {
    //   const extension = this.node.name && this.node.name.split(".")[1];
    //   console.log("extension: " + extension);
    //   return {
    //     "fas fa-file-csv": extension === "csv",
    //     "fas fa-file-code": extension === "xml",
    //     "fas fa-file-alt": extension === "txt",
    //   };
    // },
    folder() {
      console.log("type: " + this);
      return this.node.type === 2;
    },
    file() {
      return this.node.type === 1;
    },
    labelClasses() {
      return { "has-children": this.nodes };
    },

    indent() {
      return { transform: `translate(${this.depth * 30}px)` };
    },
  },
  methods: {
    toggleChildren() {
      this.showChildren = !this.showChildren;
    },
  },
};
</script>

<style scoped>
.label-wrapper {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.has-children {
  cursor: pointer;
}
</style>