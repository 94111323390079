<template>
  <div class="mt-5">
    <nav>
      <ul class="pagination justify-content-end">
        <li class="page-item">
          <a @click="first_page" class="page-link active" href="#">
            <chevrons-left-icon
              size="0.8x"
              class="custom-class"
            ></chevrons-left-icon>
          </a>
        </li>
        <li class="page-item">
          <a @click="previous_page" class="page-link active" href="#">
            <chevron-left-icon
              size="0.8x"
              class="custom-class"
            ></chevron-left-icon>
          </a>
        </li>
        <li
          v-for="index in visible_pages"
          :key="index"
          class="page-item"
          v-bind:class="{ active: index === selected_page }"
        >
          <a @click="select_page(index)" class="page-link" href="#">{{
            index
          }}</a>
        </li>
        <li class="page-item">
          <a @click="next_page" class="page-link" href="#">
            <chevron-right-icon
              size="0.8x"
              class="custom-class"
            ></chevron-right-icon>
          </a>
        </li>
        <li class="page-item">
          <a @click="last_page" class="page-link" href="#">
            <chevrons-right-icon
              size="0.8x"
              class="custom-class"
            ></chevrons-right-icon>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon
} from "vue-feather-icons";
export default {
  name: "pagination",

  props: { pages: Number },
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronsLeftIcon,
    ChevronsRightIcon
  },
  data() {
    return {
      selected_page: 1
    };
  },
  methods: {
    select_page(index) {
      this.selected_page = index;
      this.$emit("updatePage", this.selected_page);
      // this.clear_search();
    },
    previous_page() {
      if (this.selected_page > 1) {
        this.selected_page -= 1;
        this.$emit("updatePage", this.selected_page);
      }
    },
    next_page() {
      if (this.selected_page < this.pages) {
        this.selected_page += 1;
        this.$emit("updatePage", this.selected_page);
      }
    },
    first_page() {
      if (this.selected_page > 1) {
        this.selected_page = 1;
        this.$emit("updatePage", this.selected_page);
      }
    },
    last_page() {
      if (this.selected_page < this.pages) {
        this.selected_page = this.pages;
        this.$emit("updatePage", this.selected_page);
      }
    },
    paginationClass(index) {
      return index === this.selected_page ? "page-link-active" : "page-link";
    }
  },
  computed: {
    visible_pages() {
      const min = Math.max(1, this.selected_page - 5);
      const max = Math.min(this.pages, this.selected_page + 5);

      const range = [];

      for (var i = min; i < max; i++) {
        range.push(i);
      }
      range.push(max);

      return range;
    }
  }
};
</script>

<style scoped>
.page-link {
  font-size: 0.7rem;
  line-height: 1;
  color: #888;
}
.page-item {
  font-size: 0.7rem;
  line-height: 1;
  color: #888;
}
.page-item.active .page-link {
  background-color: #777f8a;
  border-color: #666c75;
}
</style>>

