<template>
  <basicLayout>
    <div class="p-3">
      <div class="card">
        <div class="card-header p-3">
          <h5>{{ email_list.list_name }}</h5>
        </div>
        <div class="card-body p-3">
          <form @submit.prevent="save_email_list">
            <div class="row">
              <div class="form-group col-6">
                <h6 for="Price">Name</h6>
                <input
                  v-model="email_list.list_name"
                  type="text"
                  class="form-control"
                  placeholder="Name e.g. Default"
                  required
                />
              </div>
              <div class="form-group col-3">
                <h6 for="Price">Enable / Disable</h6>
                <div class="custom-control custom-switch">
                  <input
                    v-model="email_list.enabled"
                    type="checkbox"
                    class="custom-control-input"
                    id="EnabledSwitch"
                  />
                  <label class="custom-control-label" for="EnabledSwitch"
                    >Enabled?</label
                  >
                </div>
              </div>
            </div>
            <div class="form-group col">
              <h6 for="exampleFormControlSelect2">Day(s) of Week</h6>
            </div>
            <div class="form-group col">
              <div class="form-check form-check-inline">
                <input
                  v-model="email_list.days"
                  class="form-check-input"
                  type="checkbox"
                  value="Monday"
                />
                <label class="form-check-label">Monday</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  v-model="email_list.days"
                  class="form-check-input"
                  type="checkbox"
                  value="Tuesday"
                />
                <label class="form-check-label">Tuesday</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  v-model="email_list.days"
                  class="form-check-input"
                  type="checkbox"
                  value="Wednesday"
                />
                <label class="form-check-label">Wednesday</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  v-model="email_list.days"
                  class="form-check-input"
                  type="checkbox"
                  value="Thursday"
                />
                <label class="form-check-label">Thursday</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  v-model="email_list.days"
                  class="form-check-input"
                  type="checkbox"
                  value="Friday"
                />
                <label class="form-check-label">Friday</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  v-model="email_list.days"
                  class="form-check-input"
                  type="checkbox"
                  value="Saturday"
                />
                <label class="form-check-label">Saturday</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  v-model="email_list.days"
                  class="form-check-input"
                  type="checkbox"
                  value="Sunday"
                />
                <label class="form-check-label">Sunday</label>
              </div>
            </div>

            <div class="form-group col-6">
              <h6>Time of Day</h6>
              <input
                v-model="email_list.time"
                type="time"
                class="form-control"
                required
              />
            </div>
            <hr />
            <div class="form-group col-6">
              <h6>Add Recipients</h6>

              <v-select
                :options="names_search"
                @input="add_recipient"
              ></v-select>
            </div>

            <div class="form-group col">
              <table class="table table-bordered table-hover table-sm">
                <thead>
                  <tr>
                    <th @click="sort_by('code')" class="w-5">Code</th>
                    <th @click="sort_by('name')" class="w-10">Name</th>
                    <th @click="sort_by('email')" class="w-10">Email</th>
                    <th class="w-5 text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="recipient in filtered_recipients"
                    :key="recipient.id"
                  >
                    <td>{{ recipient.code }}</td>
                    <td>{{ recipient.name }}</td>
                    <td>
                      <input
                        v-model="recipient.email"
                        type="email"
                        class="form-control form-control-x-sm text-"
                        multiple
                        required
                      />
                    </td>
                    <td class="w-5 text-center">
                      <button
                        @click="remove_recipient(recipient)"
                        type="button"
                        class="btn btn-x-sm btn-outline-danger"
                        title="Delete"
                      >
                        <trash-icon size="1x" class="custom-class"></trash-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="form-group col">
              <h6>Select Fields</h6>
            </div>
            <div class="form-group col">
              <div class="row">
                <div class="col">
                  <h6>Include</h6>
                  <draggable
                    class="list-group"
                    :list="email_list.report_fields_include"
                    group="fields"
                  >
                    <div
                      class="list-group-item list-group-item-success"
                      v-for="field in email_list.report_fields_include"
                      :key="field.id"
                    >
                      <strong>{{ field.display }}</strong>
                      ({{ field.name }})
                    </div>
                  </draggable>
                </div>

                <div class="col">
                  <h6>Exclude</h6>
                  <draggable
                    class="list-group"
                    :list="email_list.report_fields_exclude"
                    group="fields"
                  >
                    <div
                      class="list-group-item list-group-item-danger"
                      v-for="field in email_list.report_fields_exclude"
                      :key="field.id"
                    >
                      <strong>{{ field.display }}</strong>
                      ({{ field.name }})
                    </div>
                  </draggable>
                </div>
              </div>
            </div>
            <div class="form-group col-6">
              <h6 for="Price">Subject</h6>
              <input
                v-model="email_list.subject"
                type="text"
                class="form-control"
                placeholder="FreshOffice Stock on Hand Report"
                required
              />
            </div>
            <div class="form-group col">
              <h6 for="Text">Text</h6>
              <textarea v-model="email_list.text"></textarea>
            </div>

            <button type="submit" class="btn btn-success">Save</button>
            <button
              @click.prevent="delete_email_list"
              class="ml-3 btn btn-outline-danger"
            >
              <trash-icon size="1x" class="custom-class"></trash-icon>
              &nbsp;{{ confirm_delete ? "Really Delete?" : "Delete" }}
            </button>
          </form>
        </div>
      </div>
      <div class="card mt-3">
        <div class="card-header p-3">Test This Email</div>
        <div class="card-body p-3">
          <div class="input-group mb-3">
            <input
              v-model="email_list.test_email"
              type="email"
              class="form-control"
              placeholder="Send test to this email address"
            />
            <div class="input-group-append">
              <button
                @click.prevent="test_email"
                class="btn btn-outline-secondary"
                type="button"
              >
                Send Test
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db, fb } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import { orderBy } from "lodash";
import draggable from "vuedraggable";
import { TrashIcon } from "vue-feather-icons";

export default {
  name: "EmailListEdit",
  data() {
    return {
      names: [],
      fields: [],
      confirm_delete: false,
      sort_column: ["code"],
      sort_order: "asc",
      email_list: {
        enabled: false,
        list_name: "",
        days: [],
        time: "",
        recipients: [],
        report_fields_include: [],
        report_fields_exclude: [],
        subject: "",
        text: ""
      }
    };
  },

  components: { basicLayout, draggable, TrashIcon },
  firestore() {
    if (this.$route.params.email_list_id) {
      return {
        names: db.collection("names"),
        email_list: db
          .collection("email_lists")
          .doc(this.$route.params.email_list_id)
      };
    } else {
      return {
        names: db.collection("names"),
        fields: db.collection("feed_fields").orderBy("index")
      };
    }
  },

  watch: {
    fields: function() {
      this.email_list.report_fields_include = this.report_fields_include;
      this.email_list.report_fields_exclude = this.report_fields_exclude;
    }
  },

  methods: {
    test_email() {
      let copy_list = { ...this.email_list };
      copy_list.id = this.email_list.id;
      copy_list.test = true;
      console.log(copy_list);
      const process_email_record = fb.httpsCallable("process_email_record");
      process_email_record(copy_list).then(result => {
        console.log(JSON.stringify(result));
      });
    },

    save_email_list() {
      const email_list = this.email_list;

      if (email_list.id) {
        db.collection("email_lists")
          .doc(email_list.id)
          .set(email_list);
      } else {
        db.collection("email_lists").add(email_list);
      }
      this.$router.push("/emaillists");
    },
    async delete_email_list() {
      let delete_wait;
      if (this.confirm_delete) {
        clearTimeout(delete_wait);
        const email_list = this.email_list;
        await db
          .collection("email_lists")
          .doc(email_list.id)
          .delete();
        this.$router.push("/emaillists");
        this.confirm_delete = false;
        return;
      }
      this.confirm_delete = true;
      delete_wait = setTimeout(() => {
        this.confirm_delete = false;
      }, 4000);
    },
    add_recipient(value) {
      if (value) {
        this.email_list.recipients.push(value);
      }
    },
    remove_recipient(recipient) {
      console.log(recipient);
      // this.email_list.recipients.splice(index, 1);
      const new_list = this.email_list.recipients.filter(
        r => r.email !== recipient.email
      );
      console.log(new_list);
      this.email_list.recipients = new_list;
    },
    sort_by(column) {
      console.log("SORTING BY :" + column);
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    }
  },
  computed: {
    filtered_recipients() {
      let visible_recipients = this.email_list.recipients.slice();

      if (this.sort_column) {
        visible_recipients = orderBy(
          visible_recipients,
          item => item[this.sort_column] || "",
          this.sort_order
        );
      }
      return visible_recipients;
    },
    names_search() {
      return this.names.map(n => {
        n.label = `${n.name} (${n.code})`;
        return n;
      });
    },
    report_fields_include() {
      return this.fields.filter(f => f.default);
    },
    report_fields_exclude() {
      return this.fields.filter(f => !f.default);
    }
  }
};
</script>

<style scoped>
form table {
  font-size: 0.8rem;
}
.form-control-x-sm {
  height: calc(1.2em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.2rem;
}
.list-group-item {
  padding: 0.25rem 0.75rem;
}
textarea {
  width: 100%;
  height: 250px;
}
</style>