<template>
  <basicLayout>
    <div class="p-2">
      <h5>ONLINE ORDERS</h5>
      <div class="row">
        <div class="col-xl-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Search...</span>
            </div>
            <input
              v-model="search"
              type="text"
              class="form-control"
              placeholder="ID, Name or Company" />
            <div class="input-group-append">
              <button
                @click="clear_search"
                class="btn btn-outline-secondary"
                type="button">
                Clear
              </button>
            </div>
          </div>

          <div class="btn-toolbar">
            <button
              @click="fetch_orders"
              type="button"
              class="btn btn-sm btn-outline-secondary mr-2">
              fetch
            </button>
          </div>
        </div>
      </div>
      <hr />

      <div class="row">
        <div class="col-xl-12">
          <div class="tableFixHead">
            <table class="table table-bordered table-hover table-sm">
              <thead class="thead-dark">
                <tr>
                  <th
                    class="text-left"
                    scope="col">
                    Date
                  </th>
                  <th
                    class="text-left"
                    scope="col">
                    ID
                  </th>
                  <th
                    class="text-left"
                    scope="col">
                    Name
                  </th>
                  <th
                    class="text-left"
                    scope="col">
                    Company
                  </th>
                  <!-- <th class="text-left" scope="col">Status</th> -->
                  <th
                    class="text-right"
                    scope="col">
                    Total
                  </th>
                  <th
                    class="text-center"
                    scope="col">
                    Imported
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="order in filtered"
                  :key="order.id">
                  <td class="text-left">
                    {{ order.transaction_date | moment_long }}
                  </td>
                  <td
                    @dblclick="re_fetch_order(order)"
                    class="text-left">
                    {{ order.id }}
                  </td>
                  <td class="text-left">
                    {{ order.first_name }} {{ order.last_name }}
                  </td>
                  <td class="text-left">{{ order.company }}</td>
                  <!-- <td class="text-left">{{ order.transaction_status }}</td> -->
                  <td class="text-right">
                    {{ order.transaction_total | currency }}
                  </td>

                  <td
                    v-if="!order.imported_to_mw && !order.moneyworks_response"
                    class="text-center">
                    <button
                      @click="show_xml(order)"
                      type="button"
                      class="btn btn-x-sm btn-warning"
                      title="Failed to import to MoneyWorks">
                      MW
                    </button>
                    &nbsp;
                    <button
                      @click="show_xml(order)"
                      class="btn btn-x-sm btn-outline-secondary"
                      title="Fetch again">
                      <repeat-icon
                        size="1x"
                        class="custom-class"></repeat-icon>
                    </button>
                  </td>
                  <td
                    v-else-if="
                      order.imported_to_mw && order.moneyworks_response
                    "
                    class="text-center">
                    <button
                      @click="show_xml(order)"
                      class="btn btn-x-sm btn-success"
                      title="Succesfully imported to MoneyWorks">
                      MW
                    </button>
                  </td>
                  <td
                    v-else-if="
                      !order.imported_to_mw && order.moneyworks_response
                    "
                    class="text-center">
                    <button
                      @click="show_xml(order)"
                      class="btn btn-x-sm btn-danger"
                      title="Failed to import">
                      MW
                    </button>
                    &nbsp;
                    <button
                      @click="re_fetch_order(order)"
                      type="button"
                      class="btn btn-x-sm btn-outline-secondary"
                      title="Fetch again">
                      &#8635;
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showModal">
      <div
        class="modal fade show modal-mask"
        tabindex="-1">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ selected_order.id }}</h5>

              <button
                @click="showModal = false"
                type="button"
                class="close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <p>{{ selected_order.moneyworks_response }}</p>
              <textarea
                v-model="selected_order.xml"
                spellcheck="false"></textarea>
            </div>
            <div class="modal-footer">
              <button
                @click="showModal = false"
                type="button"
                class="btn btn-secondary">
                Close
              </button>
              <button
                @click="resubmit_xml"
                type="button"
                class="btn btn-primary">
                Resubmit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import moment from "moment";
import { fb, db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import { RepeatIcon } from "vue-feather-icons";

export default {
  name: "orders",
  data() {
    return {
      orders: [],
      search: "",
      showModal: false,
      selected_order: {},
    };
  },
  components: { basicLayout, RepeatIcon },

  firestore() {
    return {
      orders: db
        .collection("bc_orders")
        .orderBy("transaction_date", "desc")
        .limit(200),
    };
  },
  methods: {
    clear_search() {
      this.search = "";
    },
    show_xml(order) {
      this.selected_order = order;
      this.showModal = true;
      console.log(this.selected_order);
    },
    resubmit_xml() {
      console.log(this.selected_order);
      const mw_xml_resubmit = fb.httpsCallable("mw_xml_resubmit");
      mw_xml_resubmit(this.selected_order).then((result) => {
        console.log(JSON.stringify(result));
      });
      this.showModal = false;
    },
    fetch_orders() {
      const bigcommerce_fetch_all = fb.httpsCallable("bigcommerce_fetch_all");
      bigcommerce_fetch_all().then((result) => {
        console.log(JSON.stringify(result));
      });
    },
    re_fetch_order(order) {
      const bigcommerce_fetch_again = fb.httpsCallable(
        "bigcommerce_fetch_again"
      );
      bigcommerce_fetch_again(order).then((result) => {
        console.log(JSON.stringify(result));
      });
    },
  },
  computed: {
    filtered: function () {
      return this.orders.filter((order) => {
        const s = order.id + order.first_name + order.last_name + order.company;
        const su = s.toUpperCase();
        return su.match(this.search.toUpperCase());
      });
    },
  },
  filters: {
    moment_short: function (date) {
      return moment.utc(date).local().format("ll");
    },
    moment_long: function (date) {
      return moment.utc(date).local().format("LLLL");
    },
    currency: function (number) {
      return (
        number &&
        number.toLocaleString("en-NZ", { style: "currency", currency: "NZD" })
      );
    },
  },
};
</script>

<style scoped>
.modal {
  display: block;
  padding-right: 17px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.container {
  width: 100% !important;
}

.modal {
  font-size: 0.9rem;
}
textarea {
  width: 100%;
  height: 60vh;
}
</style>