<template>
  <div class="card">
    <div class="card-header">
      <h5 class="my-0 font-weight-normal">LOG</h5>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col">
          <div
            class="btn-toolbar"
            role="toolbar"
            aria-label="Toolbar with button groups"
          >
            <div class="btn-group mb-2" role="group" aria-label="First group">
              <button
                @click="log_filter('all')"
                type="button"
                class="btn btn-sm btn-outline-secondary"
              >
                all
              </button>
              <button
                @click="log_filter('error')"
                type="button"
                class="btn btn-sm btn-outline-secondary"
              >
                error
              </button>
              <button
                @click="log_filter('info')"
                type="button"
                class="btn btn-sm btn-outline-secondary"
              >
                info
              </button>
              <button
                @click="log_filter('moneyworks')"
                class="btn btn-sm btn-outline-secondary"
              >
                moneyworks
              </button>
              <button
                @click="log_filter('wms')"
                class="btn btn-sm btn-outline-secondary"
              >
                wms
              </button>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <span class="input-group-text">Search...</span>
            </div>
            <input
              @input="debounce_search"
              :value="search"
              type="text"
              class="form-control"
            />
            <div class="input-group-append">
              <button
                @click="clear_search"
                class="btn btn-outline-secondary"
                type="button"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="tableFixHead">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th class="text-left" scope="col">Date</th>
              <th class="text-left" scope="col">Message</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="log in filtered" :key="log.id" :class="log_class(log)">
              <td class="w-25 text-left">{{ log.time | moment_short }}</td>
              <td class="w-75 text-left">{{ log.message }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { fb, db } from "@/firebaseConfig";
import filter_mixins from "@/mixins/filter_mixins";

export default {
  name: "logs",
  data() {
    return {
      search: "",
      logs: [],
      log_type: "all"
    };
  },
  mixins: [filter_mixins],
  firestore: {
    logs: db
      .collection("logs")
      .orderBy("time", "desc")
      .limit(5000)
  },

  methods: {
    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 200);
    },
    clear_search() {
      this.search = "";
    },
    log_filter(type) {
      if (type) {
        this.log_type = type;
      }
    },

    logs_purge() {
      console.log("logs purge");
      const logs_purge = fb.httpsCallable("logs_purge");
      logs_purge().then(result => {
        console.log(JSON.stringify(result));
      });
    },

    log_class(log) {
      if (log.type == "info") return "text-secondary";
      if (log.type == "error") return "text-danger";
      if (log.type == "moneyworks") return "text-primary";
      if (log.type == "wms") return "text-info";
    }
  },
  computed: {
    filtered: function() {
      let visible_logs = this.logs.slice();
      let search = this.search;

      visible_logs = visible_logs.filter(log => {
        const s = Object.values(log);
        const su = s.toString().toUpperCase();
        return su.match(search.toString().toUpperCase());
      });

      if (this.log_type === "all") {
        return visible_logs;
      }
      return visible_logs.filter(l => {
        return l.type === this.log_type;
      });
    }
  }
};
</script>

<style scoped>
table {
  font-size: 0.75rem;
}
</style>

