<template>
  <basicLayout>
    <button @click="ms_graph_send_email" class="btn btn btn-primary">
      ms_graph_send_email
    </button>

    <button @click="sync_po_start" class="btn btn btn-primary">sync_po</button>

    <button @click="add_date_to_rules" class="btn btn btn-primary">
      add_date_to_rules
    </button>

    <UploadImage />
  </basicLayout>
</template>

<script>
import { fb } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import UploadImage from "@/components/UploadImage";

// const VENDOR_ID = 2655;

export default {
  name: "admin",
  data() {
    return {};
  },
  components: { basicLayout, UploadImage },

  methods: {
    ms_graph_send_email() {
      const email_object = {
        recipients: "alex@alexwebster.com, alex@coast.co.nz",
        cc_recipients: "jaewebster@gmail.com",
        subject: "Subject",
        content: "content..."
      };
      console.log("ms_graph_send_email");
      const ms_graph_send_email = fb.httpsCallable("ms_graph_send_email");
      ms_graph_send_email(email_object).then(result => {
        console.log(JSON.stringify(result));
      });
    },
    sync_po_start() {
      console.log("sync_po");
      const sync_po = fb.httpsCallable("sync_po");
      sync_po().then(result => {
        console.log(JSON.stringify(result));
      });
    }
  }
};
</script>

<style>
</style>