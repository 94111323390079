<template>
  <div class="card mr-2" style="width: 18rem">
    <div class="card-header text-white bg-success">
      <h4
        v-if="!editingName"
        @dblclick="editName"
        class="heading-text text-center"
      >
        {{ list.name }}
      </h4>

      <div v-else class="form-row align-items-center">
        <div class="my-1">
          <label class="sr-only" for="inlineFormInputName">Name</label>
          <input
            v-model="this_list.name"
            type="text"
            class="form-control"
            id="inlineFormInputName"
            placeholder="Jane Doe"
          />
        </div>

        <div class="col-auto my-1">
          <button
            @click="updateName"
            type="submit"
            class="btn btn-light btn-sm"
          >
            Save
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <draggable v-model="this_list.items" v-bind="dragOptions" @change="onEnd">
        <TaskListItem
          v-for="(item, index) in this_list.items"
          :item="item"
          :index="index"
          :items="this_list.items"
          :list="this_list"
          :board="board"
          :key="item.id"
          @item-edited="itemEdited"
          @item-cancelled="itemCancelled"
          @item-editing="itemEditing"
          @item-deleted="saveItems"
        ></TaskListItem>
      </draggable>
      <TaskListItem
        class="fixed-card"
        :item="defaultItem"
        :items="list.items"
        :list="list"
        :board="board"
        @item-edited="itemEdited"
        @item-cancelled="itemCancelled"
        @item-deleted="saveItems"
      ></TaskListItem>
    </div>
  </div>
</template>
<script>
import { db } from "@/firebaseConfig";
import Draggable from "vuedraggable";
import TaskListItem from "@/components/Kanban/TaskListItem";

export default {
  components: {
    TaskListItem,
    Draggable
  },
  props: ["list"],

  data() {
    return {
      editingName: false,
      this_list: this.list,
      board: {
        id: "1",
        name: "Project Tracker",
        description: "A board to track a project"
      },
      isEditing: false
    };
  },

  computed: {
    defaultItem() {
      return {
        title: "",
        text: ""
      };
    },
    dragOptions() {
      return {
        animation: "200",
        ghostClass: "ghost",
        group: "kanban-board-list-items",
        disabled: this.isEditing
      };
    }
  },
  methods: {
    editName() {
      console.log("editing name");
      this.editingName = true;
    },
    updateName() {
      this.editingName = false;
      this.saveItems();
    },
    onEnd(e) {
      console.log("this item: " + JSON.stringify(e));
      this.saveItems();
    },
    itemEditing() {
      this.isEditing = true;
    },
    itemEdited() {
      this.saveItems();
      this.isEditing = false;
    },
    itemCancelled() {
      this.isEditing = false;
    },
    saveItems() {
      db.collection("tasklists")
        .doc(this.list.id)
        .set(
          { items: this.this_list.items, name: this.this_list.name },
          { merge: true }
        );
    }
  }
};
</script>
