<template>
  <basicLayout>
    <h5>RECEIPT! {{ receipt.id }}</h5>
    <div class="card mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <input type="text" class="form-control" placeholder="Name" />
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="Ref" />
          </div>

          <div class="col">
            <button v- @click="receipt_update" class="btn btn-outline-success">
              Save!
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h6>ITEMS</h6>
        <div class="row">
          <div class="col-xl-6 col-lg-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">Search...</span>
              </div>
              <input
                v-model="search"
                type="text"
                class="form-control"
                placeholder="PO, SO, Name, or Carrier"
              />
              <div class="input-group-append">
                <button
                  @click="clear_search"
                  class="btn btn-outline-secondary"
                  type="button"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
        {{ receipt_lines }}
        <div class="mt-3">
          <div class="tableFixHead" ref="pick_table">
            <table class="table table-bordered table-hover table-sm">
              <thead class="thead-dark">
                <tr>
                  <th>Code</th>
                  <th>Barcode</th>
                  <th>Name</th>
                  <th class="w-10">Cat 1</th>
                  <th class="w-5">MOQ</th>
                  <th class="w-5">Mfr. Code</th>
                  <th class="w-5">Count</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in receipt_lines" :key="product.id">
                  <td>{{ product.code }}</td>
                  <td>{{ product.barcode }}</td>
                  <td>{{ product.description }}</td>
                  <td>{{ product.category1 }}</td>
                  <td>{{ product.custom5 }}</td>
                  <td class="text-right">
                    {{ product.suppliers_code | currency }}
                  </td>
                  <td>{{ product.count }}</td>
                  <td class="text-center">
                    <trash-2-icon size="1x" class="custom-class"></trash-2-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import BarcodeToast from "@/components/BarcodeToast";
import { Trash2Icon } from "vue-feather-icons";
// import { actions } from "@/store";
import sort_mixins from "@/mixins/sort_mixins";
import filter_mixins from "@/mixins/filter_mixins";
import JSPM from "jsprintmanager/JSPrintManager";

export default {
  name: "receipt_lines",
  props: { receipt: Object },
  data() {
    return {
      this_receipt: this.receipt,
      barcode: "",
      products: [],
      receipt_lines: [],
      scanned_product: "",
      search: "",
      selected_line: null,
      showModal: false,
      processing: false,
      sort_column: "",
      sort_type: "",
      sort_toggle: 1
    };
  },
  components: { basicLayout, Trash2Icon },
  mixins: [sort_mixins, filter_mixins],

  firestore() {
    return {
      products: db.collection("products"),
      receipt_lines:
        this.receipt.id &&
        db
          .collection("receipt_lines")
          .where("receipt_id", "==", `${this.receipt.id}`)
    };
  },

  created() {
    console.log("created");
    window.addEventListener("keypress", this.scanner);
    JSPM.JSPrintManager.license_url =
      "https://portal.freshoffice.co.nz/api/jspm";
    JSPM.JSPrintManager.auto_reconnect = true;
    JSPM.JSPrintManager.start();
  },
  destroyed() {
    window.removeEventListener("keypress", this.scanner);
  },

  // ================================================================
  methods: {
    // save_form: async function () {
    //   if (this.receipt.id) {
    //     console.log("UPDATING RECEIPT");
    //     const updated_receipt_data = { last_updated: new Date() };
    //     this.receipt_update(updated_receipt_data);
    //   } else {
    //     console.log("CREATING NEW RECEIPT");
    //     const new_receipt_data = { created: new Date() };
    //     console.log("new_receipt_data: " + new_receipt_data);
    //     const new_receipt = await this.receipt_create(new_receipt_data);
    //     const new_receipt_id = new_receipt.id;

    //     console.log("new_receipt_id: " + new_receipt.id);
    //     this.$router.push({
    //       name: "ReceiptEdit",
    //       params: { receipt_id: new_receipt.id },
    //     });
    //   }
    // },

    // receipt_create: async function (receipt_data) {
    //   return await db.collection("receipts").add(receipt_data);
    // },

    receipt_update: async function() {
      console.log("saving");
      this.this_receipt.last_updated = new Date();

      await db
        .collection("receipts")
        .doc(this.receipt.id)
        .update(this.this_receipt);

      this.$router.push({
        name: "Receipts"
      });
    },

    scanner(e) {
      console.log("E KEY: " + e.key);

      if (e.key != "Enter") {
        this.barcode = this.barcode + e.key;
        console.log(this.barcode);
      } else {
        console.log("BARCODE: " + this.barcode);
        let product = this.products.find(p => {
          return (
            this.barcode === p.barcode ||
            this.barcode === p.inner_barcode ||
            this.barcode === p.carton_barcode
          );
        });

        console.log(product);
        const product_id = product && product.id;
        if (!product_id) {
          const content = {
            component: BarcodeToast,
            props: {
              code: this.barcode,
              description: "that barcode is not recognised"
            }
          };
          this.$toast.error(content, {
            position: "top-right",
            timeout: 3000
          });
          console.log("that barcode is not recognised");
          this.barcode = "";
        } else {
          let qty = 1;

          if (this.barcode === product.inner_barcode) {
            qty = product.inner_qty;
          } else if (this.barcode === product.carton_barcode) {
            qty = product.carton_qty;
          }

          product.qty = qty;

          console.log("product_id!: " + product_id);
          this.print_label(product);
          this.receive_one(product);
          const content = {
            component: BarcodeToast,
            props: {
              code: product.code,
              description: product.description
            }
          };
          this.$toast.success(content, {
            position: "top-right",
            timeout: 3000
          });
          this.barcode = "";
        }
        return;
      }
    },
    print_label(product) {
      console.log("printing");
      const zpl = `

^XA
FWR
^FX GB command parameters:
^FX - width (number)
^FX - height (number)
^FX - thickness (number)
^FX - color (B/W)
^FX - rounding (number, 0-8)
^FO5,35^GB420,790,3,B,0^FS
^FO280,35^GB60,790,3,B,0^FS
^FO5,35^GB60,790,3,B,0^FS
^FO5,420^GB60,1,3,B,0^FS
^FO340,420^GB85,1,3,B,0^FS

^FO350,60^A0R,50^FD${product.code}^FS
^FO360,450^A0R,30^FD${product.supplier_code}^FS
^FO300,60^A0R,24^FD${product.description}^FS
^FO20,450^A0R,30^FD[location]^FS

^FX BC command parameters:
^FX - orientation (N/R/I/B)
^FX - height (number)
^FX - showHumanReadableText (Y/N)
^FX - showTextAbove (Y/N)
^FX - addUccCheckDigit (Y/N)
^FX - mode (A/U/D/N)
^BY3,2,150
^FO105,80^BCR^FD${product.barcode}^FS
^XZ`;

      console.log(zpl);

      JSPM.JSPrintManager.WS.onStatusChanged = function() {
        if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
          console.log("printing");
          var cpj = new JSPM.ClientPrintJob();
          cpj.clientPrinter = new JSPM.DefaultPrinter();
          cpj.printerCommands = zpl;
          cpj.sendToClient();
        }
      };
    },
    filter_by(filter) {
      this.search = filter;
    },

    clear_search() {
      this.search = "";
    },

    // ================================================================
    receive_one: async function(product) {
      const existing_product = this.receipt_lines.find(
        p => p.barcode === product.barcode
      );

      if (existing_product) {
        const count = existing_product.count || 0;
        const update_data = { count: count + product.qty };

        await db
          .collection("receipt_lines")
          .doc(existing_product.id)
          .update(update_data);
      } else {
        const update_data = {
          ...product,
          count: product.qty,
          receipt_id: this.receipt.id
        };
        await db
          .collection("receipt_lines")
          .doc(product.id)
          .set(update_data);
      }
    },
    unreceive_one: async function(existing_product) {
      const new_count = existing_product.count - 1;
      if (existing_product.count === 0) return;
      await db
        .collection("receipt_lines")
        .doc(existing_product.id)
        .update({ count: new_count });
    },

    unpick_all_line: async function(line) {
      const new_picking = line.picked + line.picking;
      if (line.picked === 0) return;

      await db
        .collection("lines")
        .doc(line.id)
        .update({ picking: new_picking, picked: 0 });
    },

    send_back_modal(line) {
      this.selected_line = line;
      this.showModal = true;
    },

    // ================================================================
    picked_column_style(line) {
      return line.picking == 0 && line.picked > 0
        ? "text-white bg-success text-right font-weight-bold"
        : "text-right font-weight-bold";
    }
  }
  // ================================================================
  // computed: {
  //   lines_to_pick() {
  //     return this.order_lines.filter(line => {
  //       return line.handling === true;
  //     });
  //   }
  // }

  // ^XA

  // FWR
  // ^FX GB command parameters:
  // ^FX - width (number)
  // ^FX - height (number)
  // ^FX - thickness (number)
  // ^FX - color (B/W)
  // ^FX - rounding (number, 0-8)
  // ^FO5,5^GB790,420,3,B,0^FS
  // ^FO5,100^GB790,60,3,B,0^FS
  // ^FO380,5^GB1,95,3,B,0^FS
  // ^FO5,360^GB790,1,3,B,0^FS

  // ^FO20,30^A0,50^FD${product.code}^FS
  // ^FO390,40^A0,30^FD${product.supplier_code}^FS
  // ^FO20,120^A0,24^FD${product.description}^FS
  // ^FO20,380^A0,30^FD[location]^FS

  // ^FX BC command parameters:
  // ^FX - orientation (N/R/I/B)
  // ^FX - height (number)
  // ^FX - showHumanReadableText (Y/N)
  // ^FX - showTextAbove (Y/N)
  // ^FX - addUccCheckDigit (Y/N)
  // ^FX - mode (A/U/D/N)
  // ^BY3,2,140
  // ^FO35,180^BC^FD${product.barcode}^FS
  // ^XZ

  // ^XA
  // ^BY3,2,300
  // ^FO15,850^BC^FD${product.barcode}^FS
  // ^FB750,10,,C
  // ^FO10,450^A0,90^FD${product.code}^FS
  // ^FO10,600^A0,40^FD${product.supplier}^FS
  // ^FO100,600^A0,40^FD${product.supplier_code}^FS
  // ^XZ`;
};
</script>

<style scoped>
.my-toast {
  font-size: 5em;
}
.modal {
  display: block;
  padding-right: 17px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

textarea {
  width: 100%;
  height: 100px;
}

@media print {
  body * {
    visibility: hidden;
    size: landscape;
  }

  .printable-table,
  .printable-table * {
    visibility: visible;
    size: landscape;
  }
  th.no-print,
  td.no-print {
    display: none;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: collapse;
  }
  tr {
    break-after: auto;
  }

  .printable-table {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 100px;
    size: landscape;
  }
  table {
    font-size: 1em;
  }
}
@media screen {
  .print-only {
    visibility: hidden;
    height: 0;
  }
}
</style>
