<template>
  <basicLayout>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6 form-group">
                <div class="custom-file">
                  <input
                    @change="file_picker"
                    type="file"
                    class="custom-file-input"
                    id="customFile"
                  />
                  <label class="custom-file-label" for="customFile"
                    >Choose file</label
                  >
                </div>
              </div>
              <div class="col-6 form-group">
                <div v-cloak @drop.prevent="drag" @dragover.prevent>
                  <input
                    class="form-control progress-bar-striped alert-dark"
                    placeholder="...or drag file here"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col mb-2">
        <EDIViewer></EDIViewer>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <EDILogs></EDILogs>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { fb } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import EDIViewer from "@/components/EDIViewer";
import EDILogs from "@/components/EDILogs";

export default {
  name: "edi",

  components: { basicLayout, EDIViewer, EDILogs },
  data() {
    return {
      list: [],
      depth: 0,
      root_node: { name: "root", type: 2 }
    };
  },
  methods: {
    drag(event) {
      this.upload(event.dataTransfer.files[0]);
      event.target.value = "";
    },
    file_picker(event) {
      // console.log("file picker");
      this.upload(event.target.files[0]);
      event.target.value = "";
    },
    upload: async function(file) {
      var reader = new FileReader();
      let edi_file;
      reader.onload = async e => {
        // The file's text will be printed here
        console.log(e.target.result);
        edi_file = {
          file_name: file.name,
          edi_content: e.target.result
        };

        console.log(edi_file);
        await this.ftp_parse_whl(edi_file);
      };
      reader.readAsText(file);
    },
    ftp_parse_whl: async function(edi_file) {
      console.log("ftp_parse_whl");
      const ftp_parse_whl = fb.httpsCallable("ftp_parse_whl");
      let res = await ftp_parse_whl(edi_file);
      console.log(JSON.stringify(res));
      this.to_moneyworks(res.data.parsed_edi);
    },
    to_moneyworks: async function(order) {
      const order_to_moneyworks = fb.httpsCallable("order_to_moneyworks");
      const order_to_moneyworks_res = await order_to_moneyworks(order);
      console.log(JSON.stringify(order_to_moneyworks_res));
    }
  }
};
</script>
