export async function starshipit_request(options) {

    const { url, method, data } = options
    const base_url = "https://api.starshipit.com/api";
    const headers = {
        "Content-Type": "application/json",
        "StarShipIT-Api-Key": "80bfdb03d83a434697643cb86bb66549",
        "Ocp-Apim-Subscription-Key": "766fb621ab2a4ec8b9fecd5005a55c3b"
    }

    try {
        const req_url = base_url + "/" + url;

        const req_options = {
            method: method,
            headers: headers
        };

        if (data) {
            req_options.body = JSON.stringify(data)
        }

        console.log("req_url: " + req_url)
        console.log("req_options: " + JSON.stringify(req_options))

        const res = await fetch(req_url, req_options);
        const res_json = await res.json();

        // console.log("starshipit res_json: " + JSON.stringify(res_json))

        return res_json

    } catch (error) {
        console.log(error);
    }
}