<template>
  <basicLayout>
    <div class="p-2">
      <h5>FEED FIELDS</h5>
      <div>
        <div class="tableFixHead">
          <table class="table table-bordered table-hover table-sm">
            <thead class="thead-dark">
              <tr>
                <th class="text-left" scope="col">Name</th>
                <th class="text-left" scope="col">Display</th>
                <th class="text-left" scope="col">Email Default?</th>
                <th class="text-left" scope="col">CSV Download</th>
              </tr>
            </thead>
            <draggable v-model="fields" tag="tbody">
              <tr v-for="field in fields" :key="field.id">
                <td class="text-left">{{ field.name }}</td>
                <td class="text-left">
                  <input
                    v-model="field.display"
                    type="text"
                    class="form-control form-control-x-sm text-left"
                  />
                </td>
                <td class="text-left">
                  <div class="form-check">
                    <input
                      v-model="field.default"
                      type="checkbox"
                      class="form-check-input"
                      title="Check to lock allocation"
                    />
                  </div>
                </td>
                <td class="text-left">
                  <div class="form-check">
                    <input
                      v-model="field.csv"
                      type="checkbox"
                      class="form-check-input"
                      title="Check to lock allocation"
                    />
                  </div>
                </td>
              </tr>
            </draggable>
          </table>
        </div>
        <hr />
        <div class="btn-toolbar mt-3">
          <button
            type="button"
            @click="save_fields"
            class="btn btn-sm btn-outline-success mr-2"
          >
            Save Changes
          </button>
          <router-link
            type="button"
            :to="{ name: 'EmailLists' }"
            class="btn btn-sm btn-outline-secondary mr-2"
            >Cancel</router-link
          >
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import draggable from "vuedraggable";

export default {
  name: "orders",
  data() {
    return {
      fields: []
    };
  },
  components: { basicLayout, draggable },

  firestore() {
    return {
      fields: db.collection("feed_fields").orderBy("index")
    };
  },
  methods: {
    save_fields() {
      this.fields.map((field, i) => {
        field.index = i;
        db.collection("feed_fields")
          .doc(field.id)
          .set(field);
      });
      this.$router.push("/emaillists");
    }
  }
};
</script>

<style scoped>
.tableFixHead {
  overflow-y: auto;
  height: 80vh;
}
</style>