<template>
  <basicLayout>
    <div class="p-2">
      <h5>RULES</h5>
      <div class="row">
        <div class="col-xl-3 mt-1 mt-xl-0">
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="customFile"
              accept=".csv"
              @change="file_picker" />
            <label
              class="custom-file-label"
              for="customFile"
              >Choose file...</label
            >
          </div>
        </div>
        <div
          class="col-xl-3 mt-2 mt-xl-0"
          v-cloak
          @drop.prevent="drag"
          @dragover.prevent>
          <input
            class="form-control progress-bar-striped alert-dark"
            placeholder="...or drag file here" />
        </div>
        <div class="col-xl-6 mt-2 mt-xl-0">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Search...</span>
            </div>
            <input
              @input="debounce_search"
              :value="search"
              type="text"
              class="form-control"
              placeholder="name code or product code" />
            <div class="input-group-append">
              <button
                @click="clear_search"
                class="btn btn-outline-secondary"
                type="button">
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="btn-toolbar">
            <button
              @click="download_csv('filtered')"
              class="btn btn-sm btn-outline-secondary mr-2">
              Download
            </button>
            <button
              @click="download_csv('all')"
              class="btn btn-sm btn-outline-secondary mr-2">
              <loader-icon
                v-if="downloading"
                size="1.5x"
                class="custom-class icon-spinner mr-2"></loader-icon>
              Download All
            </button>
          </div>
        </div>
      </div>

      <!-- <button @click="records_purge" class="btn btn btn-primary">
        records_purge
      </button> -->
      <!-- <button @click="add_rules_to_algolia" class="btn btn btn-primary">
        add_rules_to_algolia
      </button> -->
      <hr />
      <div class="row mb-2">
        <div class="col text-right">
          <b>Showing: {{ this.filtered.length }}</b> of
          <b>{{ this.rules.length }}</b>
          <br />
          <b>Selected: {{ this.selected.length }}</b> of
          <b>{{ this.filtered.length }}</b>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="tableFixHead">
            <table class="table table-bordered table-hover table-sm">
              <thead class="thead-dark">
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      v-model="selectAll"
                      @click="select" />
                  </th>
                  <th @click="sort_by('name_code')">Customer</th>
                  <th @click="sort_by('product_code')">Product</th>
                  <th @click="sort_by('price')">Price</th>
                  <th @click="sort_by('start_date')">Start</th>
                  <th @click="sort_by('end_date')">End</th>
                  <th @click="sort_by('prevailing')">Prevailing</th>
                  <th></th>
                  <th
                    @click="deleteSelected"
                    class="text-center">
                    <trash-icon
                      size="1x"
                      class="custom-class"></trash-icon>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(rule, i) in filtered"
                  :key="rule.id"
                  :class="rowClass(rule)">
                  <td>
                    <input
                      @click="selectOne($event, i)"
                      type="checkbox"
                      :value="rule.id"
                      v-model.lazy="selected" />
                  </td>
                  <td @dblclick="filter_by(rule.name_code)">
                    {{ rule.name_code.join("; ") }}
                  </td>
                  <td @dblclick="filter_by(rule.product_code)">
                    {{ rule.product_code }}
                  </td>
                  <td class="text-right">{{ rule.price }}</td>
                  <td>{{ rule.start_date }}</td>
                  <td>{{ rule.end_date }}</td>
                  <td @dblclick="filter_by(rule.prevailing)">
                    {{ rule.prevailing }}
                  </td>
                  <td class="text-center">
                    <router-link
                      :to="{ name: 'RuleEdit', params: { rule_id: rule.id } }">
                      <edit-icon
                        size="1x"
                        class="custom-class"></edit-icon>
                    </router-link>
                  </td>
                  <td
                    @click="deleteItem(rule)"
                    class="text-center">
                    <trash-icon
                      size="1x"
                      class="custom-class"></trash-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <Pagination
            :pages="pages"
            v-on:updatePage="updatePage" />
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
// import algoliasearch from "algoliasearch/lite";
import moment from "moment";
import Papa from "papaparse";
import { orderBy } from "lodash";
import { fb, db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import Pagination from "@/components/Pagination";
import filter_mixins from "@/mixins/filter_mixins";
// import sort_mixins from "@/mixins/sort_mixins";
import { EditIcon, TrashIcon, LoaderIcon } from "vue-feather-icons";

export default {
  name: "rules",
  data() {
    return {
      rules: [],
      search: "",
      debounce: null,
      sort_column: "",
      sort_order: "asc",
      selected: [],
      selectAll: false,
      savedIndex: null,
      downloading: false,
      files: [],
      count: 0,
      selected_page: 1,
      // searchClient: algoliasearch(
      //   "Q4IJ7G4460",
      //   "d7bf0ceadda22d96b2e814d98b6ce15c"
      // ),
      // algolia_index: {},
      // algolia_hits: {}
    };
  },
  components: { basicLayout, Pagination, EditIcon, TrashIcon, LoaderIcon },
  mixins: [filter_mixins],

  firestore: {
    rules: db.collection("rules"),
  },
  created: async function () {
    // this.algolia_index = this.searchClient.initIndex("rules");
    // this.algolia_hits = await this.algolia_index.search();
    this.search =
      this.$route.params.rule_id || this.$route.params.name_code || "";
  },

  methods: {
    // records_purge: async function() {
    //   const rules_purge = fb.httpsCallable("rules_purge");
    //   const res = await rules_purge();
    //   console.log(res);
    // },
    // add_rules_to_algolia: async function() {
    //   const add_to_algolia = fb.httpsCallable("add_to_algolia");

    //   const res = add_to_algolia({ table: "rules" });

    //   console.log(res);
    // },
    /*
    add_date_to_rules() {
      console.log("add_date_to_rules");
      this.rules
        // .slice(0, 1000)
        .filter(rule => {
          // const today = new Date();
          return rule.start_date;
        })
        // .slice(0, 2)
        .map(rule => {
          const rule_start_date = moment(rule.start_date, [
            "DD/MM/YYYY",
            "DD/M/YYYY",
            "YYYY-MM-DD"
          ]).format("YYYY-MM-DD");
          db.collection("rules")
            .doc(rule.id)
            .update({ start_date: rule_start_date });
        });
    },
    */
    sort_by(sort_column) {
      this.sort_column = sort_column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 500);
    },
    clear_search() {
      console.log(this.search);
      this.search = "";
      this.selected = [];
      this.selectAll = false;
      this.selected_page = 1;
    },

    updatePage(value) {
      this.selected_page = value;
      this.clear_search();
    },
    rowClass(rule) {
      return { "alert-primary": this.selected.includes(rule.id) };
    },
    selectOne(e, index) {
      const savedIndex = this.savedIndex;
      this.savedIndex = index;

      if (savedIndex === null || !e.shiftKey) return;
      if (index === savedIndex) return;

      const subset = this.filtered
        .slice(Math.min(index, savedIndex), Math.max(index, savedIndex) + 1)
        /* eslint-disable no-unused-vars */
        .map(({ id, ...rule }) => {
          return id;
        });

      if (e.currentTarget.checked) {
        if (!this.selected.length) {
          this.selected = subset;
        } else {
          this.selected = [...new Set(this.selected.concat(subset))];
        }
      } else {
        const subset_ids = new Set(subset);
        this.selected = this.selected.filter((sel) => {
          return !subset_ids.has(sel);
        });
      }
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.rules) {
          this.selected.push(this.rules[i].id);
        }
      }
    },
    drag(event) {
      this.upload(event.dataTransfer.files[0]);
      event.target.value = "";
    },
    file_picker(event) {
      console.log("file picker");
      this.upload(event.target.files[0]);
      event.target.value = "";
    },
    upload(file) {
      console.log("upload");
      console.log(file.name);
      const toast = this.$toast;
      const uploadRules = fb.httpsCallable("uploadRules");
      Papa.parse(file, {
        header: true,
        skipEmptyLines: "greedy",
        dynamicTyping: true,
        /* eslint-disable no-unused-vars */
        complete(results, file) {
          console.log(JSON.stringify(results.data));
          uploadRules(results.data).then((result) => {
            console.log(result);
            console.log(result.data);
            if (result.data.success) {
              toast.success("Rules Uploaded", {
                position: "top-right",
                timeout: 3000,
              });
            } else {
              toast.error("Rules Upload Error: " + result.data.error, {
                position: "top-right",
                timeout: 3000,
              });
            }
          });
        },
      });
    },

    filter_by(filter) {
      this.selected = [];
      this.selectAll = false;
      this.search = filter;
    },
    deleteItem(rule) {
      this.selected = [];
      this.selectAll = false;
      db.collection("rules").doc(rule.id).delete();
    },
    deleteSelected() {
      this.selected.map((rule_id) => {
        console.log(rule_id);
        db.collection("rules").doc(rule_id).delete();
      });
    },

    download_csv: async function (selection) {
      let download_rules;

      if (selection === "all") {
        console.log("download all");
        this.downloading = true;
        // const products_snapshot = await db.collection("rules").get();
        download_rules = this.rules;
      } else {
        const selected_ids = new Set(this.selected);
        download_rules = this.filtered
          .filter((c) => selected_ids.has(c.id))
          /* eslint-disable no-unused-vars */
          .map(({ id, prevailing, ...rule }) => {
            rule.name_code = rule.name_code.join(";"); // turn array into string
            console.log(rule);
            return rule;
          });
      }

      if (download_rules.length === 0) return;

      const csv = Papa.unparse({
        fields: [
          "name_code",
          "product_code",
          "price",
          "start_date",
          "end_date",
        ],
        data: download_rules,
        quotes: true,
      });
      const csv_name = moment().format("YYYY-MM-DD") + "-rules.csv";

      console.log(csv_name);
      this.downloading = false;
      const anchor = document.createElement("a");
      anchor.href = "data:text/csv," + encodeURIComponent(csv);
      anchor.download = csv_name;
      anchor.click();
    },
  },
  computed: {
    filtered() {
      let visible_rules = this.rules.slice();
      let search = this.search;

      if (this.sort_column) {
        visible_rules = orderBy(
          visible_rules,
          this.sort_column,
          this.sort_order
        );
      }

      if (!search) {
        let start = (this.selected_page - 1) * 300;
        let end = start + 300;
        visible_rules = visible_rules.slice(start, end);
      }

      visible_rules = visible_rules.filter((rule) => {
        const s = Object.values(rule);
        const su = s.toString().toUpperCase();
        return su.match(search.toString().toUpperCase());
      });

      return visible_rules;
    },

    pages() {
      return Math.ceil(this.rules.length / 300);
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    search: function (newSearch) {
      if (newSearch) {
        this.selected = [];
        this.selectAll = false;
        this.selected_page = -1;
      } else {
        this.selected_page = 1;
      }
    },
  },
};
</script>

<style scoped>
.container {
  width: 100% !important;
}

@keyframes anim-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.icon-spinner {
  display: inline-block;
  animation: anim-rotate 2s infinite linear;
}
.icon-spinner--steps {
  animation: anim-rotate 1s infinite steps(8);
}
.icon-spinner--steps2 {
  animation: anim-rotate 1s infinite steps(12);
}
</style>
<style >
td em {
  font-style: normal;
  font-weight: bold;
  background-color: #cedcec;
}
</style>
