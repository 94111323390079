<template>
  <div>
    <div v-if="!isEditing" @click.prevent="startEditing">
      <div class="card m-2">
        <div class="card-header">{{ displayText }}</div>
        <div class="card-body">
          <small>{{ item.text }}</small>
        </div>
      </div>
    </div>

    <li v-else class="list-group-item">
      <form class="form">
        <div class="form-group">
          <input
            name="itemTitle"
            class="form-control"
            v-model.trim="form.title"
            placeholder="Your item title"
          />
        </div>
        <div class="form-group">
          <textarea
            name="itemDetails"
            rows="6"
            class="form-control"
            v-model.trim="form.text"
            placeholder="Your item description"
          ></textarea>
        </div>

        <div
          :class="[
            isNewItem ? 'text-center' : 'd-flex justify-content-between',
            'form-group',
          ]"
        >
          <div>
            <button
              class="btn btn-outline-secondary btn-sm mr-2"
              @click.prevent="save"
            >
              Save
            </button>
            <button
              class="btn btn-outline-secondary btn-sm"
              @click.prevent="cancel"
            >
              Cancel
            </button>
          </div>
          <div v-show="!isNewItem">
            <button class="btn btn-sm text-danger" @click.prevent="remove">
              Delete
            </button>
          </div>
        </div>
      </form>
    </li>
  </div>
</template>
<script>
export default {
  props: ["item", "index", "items", "list", "board"],
  computed: {
    isNewItem() {
      return this.item.title == "";
    },
    displayText() {
      return this.isNewItem ? "+ New Item" : this.item.title;
    }
  },
  data() {
    return {
      isEditing: false,
      this_items: this.items,
      form: {
        title: "",
        text: ""
      }
    };
  },
  methods: {
    startEditing() {
      console.log("item: " + JSON.stringify(this.item));
      this.form.title = this.item.title;
      this.form.text = this.item.text;
      this.isEditing = true;
      this.$emit("item-editing");
    },

    clearForm() {
      this.form.title = "";
      this.form.text = "";
    },
    save() {
      const title = this.form.title
        .split(" ")
        .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(" ");

      if (this.isNewItem) {
        this.this_items.push(this.form);
      } else {
        this.this_items[this.index].title = title;
        this.this_items[this.index].text = this.form.text;
      }

      console.log("form: " + JSON.stringify(this.form));

      this.isEditing = false;
      this.$emit("item-edited");
    },
    cancel() {
      this.isEditing = false;
      this.$emit("item-cancelled");
    },
    remove() {
      this.this_items.splice(this.index, 1);
      this.isEditing = false;
      this.$emit("item-deleted");
    }
  }
};
</script>
