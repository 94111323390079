<template>
  <basicLayout>
    <h5>RECEIPT</h5>
    <div class="card mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <input
              v-model="receipt.name"
              type="text"
              class="form-control"
              placeholder="Name"
            />
          </div>
          <div class="col">
            <input
              v-model="receipt.ref"
              type="text"
              class="form-control"
              placeholder="Ref"
            />
          </div>

          <div class="col">
            <button
              @click="receipt_update"
              class="btn btn-outline-success mr-2"
            >
              Save
            </button>

            <button
              @click="receipt_close"
              class="btn btn-outline-secondary mr-2"
            >
              Close
            </button>
            <a
              @click="download_csv('selected')"
              :disabled="this.receipt_lines.length === 0"
              class="btn btn-outline-secondary"
              role="button"
              >Download
            </a>
          </div>

          <div class="col">
            <div class="custom-control custom-switch">
              <input
                v-model="print"
                type="checkbox"
                class="custom-control-input"
                id="customSwitch1"
              />
              <label class="custom-control-label" for="customSwitch1"
                >Print label on scan</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h6>ITEMS</h6>
        <div class="row">
          <div class="col-xl-6 col-lg-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">Search...</span>
              </div>
              <input
                v-model="search"
                type="text"
                class="form-control"
                placeholder="PO, SO, Name, or Carrier"
              />
              <div class="input-group-append">
                <button
                  @click="clear_search"
                  class="btn btn-outline-secondary"
                  type="button"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <div v-if="receipt_lines" class="tableFixHead" ref="pick_table">
            <table class="table table-bordered table-hover table-sm">
              <thead class="thead-dark">
                <tr>
                  <th>Code</th>
                  <th>Barcode</th>
                  <th>Name</th>
                  <th class="w-10">Cat 1</th>
                  <th class="w-5">MOQ</th>
                  <th class="w-5">Mfr. Code</th>
                  <th></th>
                  <th class="w-5">Count</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in receipt_lines" :key="product.id">
                  <td>{{ product.code }}</td>
                  <td>{{ product.barcode }}</td>
                  <td>{{ product.description }}</td>
                  <td>{{ product.category1 }}</td>
                  <td>{{ product.custom5 }}</td>
                  <td class="text-right">
                    {{ product.suppliers_code | currency }}
                  </td>
                  <td class="text-center w-20 no-print">
                    <button
                      @click="receive_one(product)"
                      type="button"
                      class="btn btn-x-sm btn-outline-success mr-2"
                    >
                      +1
                    </button>
                    <button
                      @click="unreceive_one(product)"
                      type="button"
                      class="btn btn-x-sm btn-outline-danger mr-2"
                    >
                      - 1
                    </button>

                    <button
                      @click="unreceive_all_line(product)"
                      type="button"
                      class="btn btn-x-sm btn-outline-danger mr-2"
                    >
                      Clear
                    </button>
                  </td>
                  <td>
                    <input
                      v-model="product.count"
                      type="number"
                      class="form-control form-control-x-sm text-right"
                    />
                  </td>
                  <td class="text-center">
                    <trash-2-icon size="1x" class="custom-class"></trash-2-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import BarcodeToast from "@/components/BarcodeToast";
import { Trash2Icon } from "vue-feather-icons";
import sort_mixins from "@/mixins/sort_mixins";
import filter_mixins from "@/mixins/filter_mixins";
import moment from "moment";
import JSPM from "jsprintmanager/JSPrintManager";
import Papa from "papaparse";

export default {
  name: "receipt_lines",

  data() {
    return {
      is_editing: true,
      print: false,
      barcode: "",
      products: [],
      receipt: {},
      receipt_lines: [],
      scanned_product: "",
      search: "",
      selected_line: null,
      showModal: false,
      processing: false,
      sort_column: "",
      sort_type: "",
      sort_toggle: 1
    };
  },
  components: { basicLayout, Trash2Icon },
  mixins: [sort_mixins, filter_mixins],

  firestore() {
    return {
      products: db.collection("products"),
      receipt: db.collection("receipts").doc(this.$route.params.receipt_id),
      receipt_lines: db
        .collection("receipt_lines")
        .where("receipt_id", "==", `${this.$route.params.receipt_id}`)
    };
  },

  created() {
    // actions.setLoading(true);
    window.addEventListener("keypress", this.scanner);
    JSPM.JSPrintManager.license_url =
      "https://portal.freshoffice.co.nz/api/jspm";
    JSPM.JSPrintManager.auto_reconnect = true;
    JSPM.JSPrintManager.start();
  },
  destroyed() {
    window.removeEventListener("keypress", this.scanner);
  },
  watch: {
    // receipt_lines: function (new_lines) {
    //   if (new_lines.length > 1) {
    //     actions.setLoading(false);
    //   }
    // }
  },

  // ================================================================
  methods: {
    receipt_update: async function() {
      console.log("save");
      // this.is_editing=false
      this.receipt.last_updated = new Date();
      db.collection("receipts")
        .doc(this.receipt.id)
        .update(this.receipt);
      // this.$router.push("/receipts");
    },
    receipt_close: function() {
      this.$router.push("/receipts");
    },

    scanner(e) {
      console.log("E KEY: " + e.key);

      if (e.key != "Enter") {
        this.barcode = this.barcode + e.key;
        console.log(this.barcode);
      } else {
        console.log("BARCODE: " + this.barcode);
        let product = this.products.find(p => {
          return (
            this.barcode === p.barcode ||
            this.barcode === p.inner_barcode ||
            this.barcode === p.carton_barcode
          );
        });

        console.log(product);
        const product_id = product && product.id;
        if (!product_id) {
          const content = {
            component: BarcodeToast,
            props: {
              code: this.barcode,
              description: "that barcode is not recognised"
            }
          };
          this.$toast.error(content, {
            position: "top-right",
            timeout: 3000
          });
          console.log("that barcode is not recognised");
          this.barcode = "";
        } else {
          let qty = 1;

          if (this.barcode === product.inner_barcode) {
            qty = Number(product.inner_qty);
          } else if (this.barcode === product.carton_barcode) {
            qty = Number(product.carton_qty);
          }

          product.qty = qty;

          console.log("product_id: " + product_id);
          if (this.print) {
            this.print_label(product);
          }
          this.receive_one(product);
          const content = {
            component: BarcodeToast,
            props: {
              code: product.code,
              description: product.description
            }
          };
          this.$toast.success(content, {
            position: "top-right",
            timeout: 3000
          });
          this.barcode = "";
        }
        return;
      }
    },
    print_label(product) {
      console.log("printing");
      console.log(product.id);
      const zpl = `

^XA
FWR
^FX GB command parameters:
^FX - width (number)
^FX - height (number)
^FX - thickness (number)
^FX - color (B/W)
^FX - rounding (number, 0-8)
^FO5,35^GB420,790,3,B,0^FS
^FO280,35^GB60,790,3,B,0^FS
^FO5,35^GB60,790,3,B,0^FS
^FO5,420^GB60,1,3,B,0^FS
^FO340,420^GB85,1,3,B,0^FS

^FO350,60^A0R,50^FD${product.code}^FS
^FO360,450^A0R,30^FD${product.supplier_code}^FS
^FO300,60^A0R,24^FD${product.description}^FS
^FO20,450^A0R,30^FD[location]^FS

^FX BC command parameters:
^FX - orientation (N/R/I/B)
^FX - height (number)
^FX - showHumanReadableText (Y/N)
^FX - showTextAbove (Y/N)
^FX - addUccCheckDigit (Y/N)
^FX - mode (A/U/D/N)
^BY3,2,150
^FO105,80^BCR^FD${product.barcode}^FS
^XZ`;

      // JSPM.JSPrintManager.WS.onStatusChanged = function () {
      //   if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
      //     console.log("printing");
      var cpj = new JSPM.ClientPrintJob();
      cpj.clientPrinter = new JSPM.DefaultPrinter();
      cpj.printerCommands = zpl;
      cpj.sendToClient();
      // }
      // };
    },
    filter_by(filter) {
      this.search = filter;
    },

    clear_search() {
      this.search = "";
    },

    // ================================================================
    receive_one: async function(product) {
      const existing_product = this.receipt_lines.find(
        p => p.barcode === product.barcode
      );

      if (existing_product) {
        const count = Number(existing_product.count) || 0;
        const update_data = { count: count + Number(product.qty) };

        await db
          .collection("receipt_lines")
          .doc(existing_product.id)
          .update(update_data);
      } else {
        const update_data = {
          ...product,
          count: Number(product.qty),
          receipt_id: this.receipt.id
        };
        await db
          .collection("receipt_lines")
          .doc(product.id)
          .set(update_data);
      }
    },
    unreceive_one: async function(product) {
      const new_count = Number(product.count) - 1;
      if (product.count === 0) return;
      await db
        .collection("receipt_lines")
        .doc(product.id)
        .update({ count: new_count });
    },

    unreceive_all_line: async function(product) {
      if (Number(product.count) === 0) return;
      await db
        .collection("receipt_lines")
        .doc(product.id)
        .update({ count: 0 });
    },
    /* eslint-disable no-unused-vars */
    download_csv(_selection, _$event) {
      let csv_export = this.receipt_lines;
      const csv = Papa.unparse({
        data: csv_export,
        quotes: true
      });

      const csv_name = `${this.receipt.ref}-receipt-${moment().format(
        "DD-MM-YYYY"
      )}.csv`;

      event.target.href = "data:text/csv," + encodeURIComponent(csv);
      event.target.download = csv_name;
    }
  },
  beforeRouteLeave(_to, _from, next) {
    this.$toast.warning("Saving Changes");
    this.receipt_update();
    next();
  }
};
</script>

<style scoped>
.my-toast {
  font-size: 5em;
}
.modal {
  display: block;
  padding-right: 17px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

textarea {
  width: 100%;
  height: 100px;
}

@media print {
  body * {
    visibility: hidden;
    size: landscape;
  }

  .printable-table,
  .printable-table * {
    visibility: visible;
    size: landscape;
  }
  th.no-print,
  td.no-print {
    display: none;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: collapse;
  }
  tr {
    break-after: auto;
  }

  .printable-table {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 100px;
    size: landscape;
  }
  table {
    font-size: 1em;
  }
}
@media screen {
  .print-only {
    visibility: hidden;
    height: 0;
  }
}
</style>
