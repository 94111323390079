<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col"><h5>EDI VIEWER</h5></div>
        <div class="col text-right">
          <button @click="ftp_directory" class="btn btn-outline-success">
            Refresh
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <tree-menu
        label="root"
        :node="root_node"
        :nodes="list"
        :depth="0"
      ></tree-menu>
    </div>
  </div>
</template>

<script>
import TreeMenu from "@/components/EDITreeMenu";
import { fb } from "@/firebaseConfig";

export default {
  name: "edi_viewer",

  components: { TreeMenu },
  data() {
    return {
      list: [],
      depth: 0,
      root_node: { name: "root", type: 2 },
    };
  },

  methods: {
    ftp_directory() {
      const ftp_dir = fb.httpsCallable("ftp_dir");

      ftp_dir({ text: "ftp_dir" }).then((result) => {
        console.log(JSON.stringify(result));
        this.list = result.data;
      });
    },
  },
  computed: {},
};
</script>
