import { render, staticRenderFns } from "./EmailListEdit.vue?vue&type=template&id=70f0cf03&scoped=true&"
import script from "./EmailListEdit.vue?vue&type=script&lang=js&"
export * from "./EmailListEdit.vue?vue&type=script&lang=js&"
import style0 from "./EmailListEdit.vue?vue&type=style&index=0&id=70f0cf03&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70f0cf03",
  null
  
)

export default component.exports