<template>
  <div class="container-fluid text-center">
    <div class="row border-bottom p-2">
      <div class="col border-right">
        <h4>{{ scanned_product.code }}</h4>
      </div>
      <div class="col">
        <h4>{{ scanned_product.supplier_code }}</h4>
      </div>
    </div>
    <div class="row border-bottom p-2">
      <div class="col text-uppercase">
        <h4>{{ scanned_product.description }}</h4>
      </div>
    </div>
    <div class="row border-bottom p-2">
      <div class="col">
        <barcode v-bind:value="scanned_product.barcode">
          Show this if the rendering fails.
        </barcode>
      </div>
    </div>
    <div class="row p-2">
      <div class="col border-right">
        <h4>{{ scanned_product.code }}</h4>
      </div>
      <div class="col">
        <h4>{{ scanned_product.supplier_code }}</h4>
      </div>
    </div>
    <button
      @click="edit_product"
      type="submit"
      class="btn btn-light text-success"
    >
      Edit
    </button>
  </div>
</template>

<script>
import VueBarcode from "vue-barcode";

export default {
  name: "scan_popup",
  props: { scanned_product: Object },
  components: { barcode: VueBarcode },
  methods: {
    edit_product() {
      this.$emit("edit_product");
    }
  }
};
</script>