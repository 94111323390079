import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { actions } from "@/store";
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const toast_options = {
  transition: "Vue-Toastification__fade",
  maxToasts: 10,
  newestOnTop: true,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
};

import { auth } from "@/firebaseConfig";
import './styles/style.css';

import { firestorePlugin } from 'vuefire'
Vue.component('v-select', vSelect)
Vue.config.productionTip = false
Vue.use(firestorePlugin)
Vue.use(Toast, toast_options);

// LOGROCKET

import LogRocket from 'logrocket';
LogRocket.init('jpjw0c/freshoffice');

/* eslint-disable no-unused-vars */
let app;

auth.onAuthStateChanged(user => {

  if (user) {
    actions.fetchCurrentUserProfile(user);
  }
  if (!app) {
    app = new Vue({
      router,
      render: h => h(App)
    }).$mount("#app");
  }
});

// auth.onAuthStateChanged(user => {
//   console.log("onAuthStateChanged - user")
//   if (user) {
//     //mutations.setCurrentUser(user);
//     //actions.fetchCurrentUserProfile(user);
//   }
// });