import { firebase } from '@firebase/app'
import '@firebase/firestore'
import '@firebase/auth'
import '@firebase/functions'
import '@firebase/storage'

const config = {
  apiKey: "AIzaSyC-VShDoOe3s1wPXulhP1PzI77rI_TADr4",
  authDomain: "freshofficeportal.firebaseapp.com",
  databaseURL: "https://freshofficeportal.firebaseio.com",
  projectId: "freshofficeportal",
  storageBucket: "freshofficeportal.appspot.com",
  messagingSenderId: "195467519550",
  appId: "1:195467519550:web:0165f2db2cd4ad57b6536f",
  measurementId: "G-6TWY9RZZTG"
}

firebase.initializeApp(config)

const db = firebase.firestore()
const fb = firebase.functions()
const auth = firebase.auth()
const storage = firebase.storage()
const currentUser = auth.currentUser

export {
  db,
  fb,
  auth,
  storage,
  currentUser
}
