<template>
  <div>
    <div>
      <button
        @click="openUploadModal"
        class="btn btn-outline-success btn-block"
      >
        Upload files
      </button>
    </div>
    <div class="row mt-2">
      <div class="col-6">
        <div class="custom-file">
          <input
            type="file"
            class="custom-file-input"
            id="customFile"
            accept="image/png, image/jpeg"
            @change="file_picker"
          />
          <label class="custom-file-label" for="customFile"
            >Choose file...</label
          >
        </div>
      </div>
      <div class="col" v-cloak @drop.prevent="drag" @dragover.prevent>
        <input
          class="form-control progress-bar-striped alert-dark"
          placeholder="...or drag file here"
        />
      </div>
    </div>

    <div class="row mt-2">
      <div v-show="image_preview">
        <img v-bind:src="image_preview" class="image-preview" />
        {{ image_preview }}
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6 offset-md-6">
        <button
          @click="upload_image"
          class="btn btn-outline-success btn-block"
          type="submit"
        >
          Upload This Image
        </button>
      </div>
    </div>
    <div class="row mt-2">
      {{ upload_progress }}
      <div v-if="upload_progress > 0 && upload_progress < 99" class="col mt-3">
        <div class="progress">
          <div
            class="progress-bar progress-bar-striped"
            role="progressbar"
            :style="{ width: upload_progress + '%' }"
            :key="upload_progress"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        {{ image_preview }}
        {{ results }}
      </div>
    </div>
  </div>
</template>

<script>
// import { clipperUpload, clipperFixed, clipperPreview } from "vuejs-clipper";
import axios from "axios";

export default {
  props: {
    track: Object
  },
  data() {
    return {
      file: "",
      image_preview: "",
      upload_progress: 0,
      results: ""
    };
  },

  // mounted() {
  //   this.$refs.clipper.onChange$.subscribe(a => {
  //     console.log("onChange: " + this.$refs.preview.outerHeight);
  //     this.img_height = parseInt(this.$refs.preview.outerHeight);
  //     this.img_width = parseInt(this.$refs.preview.outerWidth);
  //   });
  //   if (this.track.imURL) {
  //     this.imgURL = this.track.imgURL;
  //   }
  // },

  methods: {
    openUploadModal() {
      window.cloudinary
        .openUploadWidget(
          {
            cloud_name: "freshoffice",

            cropping: true,
            croppingAspectRatio: 1,
            minImageWidth: 1400,
            minImageHeight: 1400,
            croppingShowDimensions: true,
            croppingValidateDimensions: true,
            croppingShowBackButton: true,
            showSkipCropButton: false,
            uploadPreset: "album_cover",
            theme: "minimal"
          },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result.info);
            }
          }
        )
        .open();
    },
    drag(event) {
      this.file = event.dataTransfer.files[0];
      this.load_image(event.dataTransfer.files[0]);
      event.target.value = "";
    },
    file_picker(event) {
      console.log("file picker");
      this.file = event.target.files[0];
      this.load_image(event.target.files[0]);
      event.target.value = "";
    },

    load_image: function(file) {
      var reader = new FileReader();
      reader.onload = e => {
        this.image_preview = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    upload_image: function() {
      const cloudName = "freshoffice";
      const upload_data = {
        url: `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        method: "POST",
        data: {
          file: this.image_preview,
          upload_preset: "fresh_unsigned",
          public_id: this.file.name,
          description: "description_test",
          tags: ["test", "tag_test"]
        },
        onUploadProgress: function(progressEvent) {
          let progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log(progress);
          this.upload_progress = progress;
        }.bind(this)
      };
      // const config = {
      //   onUploadProgress: function(progressEvent) {
      //     let progress = Math.round(
      //       (progressEvent.loaded * 100) / progressEvent.total
      //     );
      //     this.progress = progress;
      //   }
      // };

      axios(upload_data).then(response => {
        this.results = response.data;
      });
    }
  }
};
</script>


<style scoped>
.my-clipper {
  width: 100%;
  max-width: 700px;
}

.placeholder {
  text-align: center;
  padding: 20px;
  height: 300px;
  background-color: rgba(0, 123, 255, 0.11);
}
.image-preview {
  width: 200px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
</style>