<template>
  <basicLayout>
    <div class="p-2">
      <div class="markdown" v-html="compiledMarkdown"></div>
    </div>
  </basicLayout>
</template>

<script>
import basicLayout from "@/components/basicLayout";
import docs_text from "raw-loader!@/assets/docs.md";
import marked from "marked";
export default {
  data() {
    return { input: docs_text };
  },
  components: { basicLayout },
  computed: {
    compiledMarkdown: function() {
      return marked(this.input);
    }
  }
};
</script>

<style scoped>
textarea {
  display: inline-block;
  height: 100%;
  vertical-align: top;
  box-sizing: border-box;
  padding: 0 20px;
}

div.markdown /deep/ table {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  border-spacing: 0;
  border: 1px solid #555;
}
div.markdown /deep/ table th {
  padding: 3px 10px;
  background-color: white;
  border: 1px solid #555;
}
div.markdown /deep/ table td {
  padding: 3px 10px;
  border: 1px solid #555;
}

textarea {
  border: none;
  border-right: 1px solid #ccc;
  resize: none;
  outline: none;
  background-color: #f6f6f6;
  font-size: 14px;
  font-family: "Monaco", courier, monospace;
  padding: 20px;
}

code {
  color: #f66;
}
</style>