<template>
  <basicLayout>
    <div class="p-3">
      <div class="card">
        <div class="card-header p-3">
          <div class="row">
            <div class="col">
              <h4>{{ customer.code }} - {{ customer.name }}</h4>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <button
                @click="$router.go(-1)"
                class="btn btn-sm btn-outline-secondary"
                type="button"
              >
                <arrow-left-icon
                  size="1.0x"
                  class="custom-class"
                ></arrow-left-icon>
                Back
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6">
              <form>
                <div class="form-group">
                  <label for="api_key">API Key</label>
                  <div class="input-group mb-3">
                    <input
                      v-model="customer.api_key"
                      type="text"
                      class="form-control"
                      readonly
                    />
                    <div class="input-group-append">
                      <button
                        @click="reset_api_key"
                        class="btn btn-outline-secondary"
                        type="button"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>

                <router-link
                  :to="{
                    name: 'Customers',
                  }"
                  class="btn btn-secondary"
                >
                  Cancel
                </router-link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { fb, db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import { ArrowLeftIcon } from "vue-feather-icons";

export default {
  name: "CustomerEdit",
  data() {
    return {
      customer: { api_key: "" }
    };
  },
  components: { basicLayout, ArrowLeftIcon },
  firestore() {
    if (this.$route.params.customer_id) {
      return {
        customer: db.collection("names").doc(this.$route.params.customer_id)
      };
    }
  },

  methods: {
    reset_api_key() {
      console.log("reset_api_key");
      console.log("this.customer: " + JSON.stringify(this.customer.id));
      const reset_api_key = fb.httpsCallable("reset_api_key");
      reset_api_key(this.customer.id).then(result => {
        console.log(JSON.stringify(result));
      });
    }
  }
};
</script>

<style scoped>
</style>