<template>
  <basicLayout>
    <div class="p-3">
      <div class="card">
        <div class="card-header p-3">
          <div class="row">
            <div class="col">
              <h4>{{ product.code }}</h4>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <h5>{{ product.description }}</h5>
              <p class="small">
                <span class="font-weight-bold"> SUPPLIER'S CODE:</span>
                {{ product.suppliers_code }}<br />
                <span class="font-weight-bold"> SELL UNIT:</span>
                {{ product.sell_unit }}<br />
                <span class="font-weight-bold"> MOQ:</span>
                {{ product.custom5 }}
              </p>
            </div>

            <div class="col text-right">
              <barcode
                v-bind:value="product.barcode"
                height="40"
                fontSize="10"
                width="2">
                <span class="small">NO BARCODE</span>
              </barcode>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button
                @click="$router.go(-1)"
                class="btn btn-sm btn-outline-secondary"
                type="button">
                <arrow-left-icon
                  size="1.0x"
                  class="custom-class"></arrow-left-icon>
                Back
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6 order-2 text-right">
              <img
                v-if="product.code"
                :src="`https://res.cloudinary.com/fresh-office/image/upload/products/${product.code}.jpg`"
                :alt="`${product.description}`"
                @error="
                  $event.target.src = 'https://res.cloudinary.com/fresh-office/image/upload/products/no_image.png'
                "
                class="img-thumbnail"
                width="200px" />
            </div>

            <div class="col-6 order-1">
              <form>
                <div class="form-group">
                  <label for="location">ETA</label>
                  <input
                    v-model="product.eta"
                    type="date"
                    class="form-control"
                    required />
                </div>
                <div class="form-group">
                  <label for="location">Location</label>
                  <input
                    v-model="product.pick_location"
                    type="text"
                    class="form-control"
                    required />
                </div>
                <div class="form-group">
                  <label for="bulk_location_1">Bulk Location 1</label>
                  <input
                    v-model="product.bulk_location_1"
                    type="text"
                    class="form-control"
                    required />
                </div>
                <div class="form-group">
                  <label for="bulk_location_2">Bulk Location 2</label>
                  <input
                    v-model="product.bulk_location_2"
                    type="text"
                    class="form-control"
                    required />
                </div>
                <div class="form-group">
                  <label for="barcode">Unit Barcode</label>
                  <input
                    v-model="product.barcode"
                    type="text"
                    class="form-control"
                    required />
                </div>
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="unit_length">Unit Length (mm)</label>
                    <input
                      v-model="product.unit_length"
                      type="number"
                      class="form-control"
                      id="unit_length" />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="unit_width">Unit Width (mm)</label>
                    <input
                      v-model="product.unit_width"
                      type="number"
                      class="form-control"
                      id="unit_width" />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="unit_height">Unit Height (mm)</label>
                    <input
                      v-model="product.unit_height"
                      type="number"
                      class="form-control"
                      id="unit_height" />
                  </div>
                </div>

                <div class="form-group">
                  <label for="unit_weight">Unit Weight (g)</label>
                  <input
                    v-model="product.unit_weight"
                    type="number"
                    class="form-control"
                    required />
                </div>
                <div class="form-group">
                  <div class="form-check">
                    <input
                      v-model="product.dangerous_goods"
                      class="form-check-input"
                      type="checkbox" />
                    <label
                      class="form-check-label"
                      for="defaultCheck1">
                      Dangerous Goods
                    </label>
                  </div>
                </div>
                <hr />
                <div class="form-group">
                  <label for="code">Pack Barcode</label>
                  <input
                    v-model="product.pack_barcode"
                    type="text"
                    class="form-control"
                    required />
                </div>

                <div class="form-group">
                  <label for="pack_qty">Pack Quantity</label>
                  <input
                    v-model="product.pack_qty"
                    type="number"
                    class="form-control"
                    required />
                </div>

                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="pack_length">Pack Length (mm)</label>
                    <input
                      v-model="product.pack_length"
                      type="number"
                      class="form-control"
                      id="pack_length" />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="pack_width">Pack Width (mm)</label>
                    <input
                      v-model="product.pack_width"
                      type="number"
                      class="form-control"
                      id="pack_width" />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="pack_height">Pack Height (mm)</label>
                    <input
                      v-model="product.pack_height"
                      type="number"
                      class="form-control"
                      id="pack_height" />
                  </div>
                </div>

                <div class="form-group">
                  <label for="pack_weight">Pack Weight (g)</label>
                  <input
                    v-model="product.pack_weight"
                    type="number"
                    class="form-control"
                    required />
                </div>

                <hr />
                <div class="form-group">
                  <label for="inner_barcode">Inner Barcode</label>
                  <input
                    v-model="product.inner_barcode"
                    type="text"
                    class="form-control"
                    required />
                </div>
                <div class="form-group">
                  <label for="inner_qty">Inner Quantity</label>
                  <input
                    v-model="product.inner_qty"
                    type="number"
                    class="form-control"
                    required />
                </div>

                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="inner_length">Inner Length (mm)</label>
                    <input
                      v-model="product.inner_length"
                      type="number"
                      class="form-control"
                      id="inner_length" />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inner_width">Inner Width (mm)</label>
                    <input
                      v-model="product.inner_width"
                      type="number"
                      class="form-control"
                      id="inner_width" />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inner_height">Inner Height (mm)</label>
                    <input
                      v-model="product.inner_height"
                      type="number"
                      class="form-control"
                      id="inner_height" />
                  </div>
                </div>

                <div class="form-group">
                  <label for="inner_weight">Inner Weight (g)</label>
                  <input
                    v-model="product.inner_weight"
                    type="number"
                    class="form-control"
                    required />
                </div>
                <hr />
                <div class="form-group">
                  <label for="carton_barcode">Carton Barcode</label>
                  <input
                    v-model="product.carton_barcode"
                    type="text"
                    class="form-control"
                    required />
                </div>
                <div class="form-group">
                  <label for="carton_qty">Carton Quantity</label>
                  <input
                    v-model="product.carton_qty"
                    type="number"
                    class="form-control"
                    required />
                </div>

                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="carton_length">Carton Length (mm)</label>
                    <input
                      v-model="product.carton_length"
                      type="number"
                      class="form-control"
                      id="carton_length" />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="carton_width">Carton Width (mm)</label>
                    <input
                      v-model="product.carton_width"
                      type="number"
                      class="form-control"
                      id="carton_width" />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="carton_height">Carton Height (mm)</label>
                    <input
                      v-model="product.carton_height"
                      type="number"
                      class="form-control"
                      id="carton_height" />
                  </div>
                </div>
                <div class="form-group">
                  <label for="carton_weight">Carton Weight (g)</label>
                  <input
                    v-model="product.carton_weight"
                    type="number"
                    class="form-control"
                    required />
                </div>
                <hr />
                <div class="form-group">
                  <label for="carton_weight">RRP inc.</label>
                  <input
                    v-model="product.rrp_inc"
                    type="number"
                    step="0.01"
                    class="form-control"
                    required />
                </div>
                <div class="form-group">
                  <label for="track_delivery_territories"> Country of Origin </label>
                  <select
                    v-model="product.country_origin"
                    class="custom-select d-block w-100">
                    <option
                      v-for="country in countries"
                      :key="country">
                      {{ country }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="carton_weight">Long Description</label>
                  <textarea
                    v-model="product.long_description"
                    class="form-control"
                    rows="3"
                    disabled></textarea>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="carton_length">Bullet Point 1</label>
                    <input
                      v-model="product.bullet_1"
                      type="text"
                      class="form-control"
                      id="bullet_1" />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="carton_width">Bullet Point 2</label>
                    <input
                      v-model="product.bullet_2"
                      type="text"
                      class="form-control"
                      id="bullet_2" />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="carton_height">Bullet Point 3</label>
                    <input
                      v-model="product.bullet_3"
                      type="text"
                      class="form-control"
                      id="bullet_3" />
                  </div>

                  <div class="form-group col-md-6">
                    <label for="carton_length">Bullet Point 4</label>
                    <input
                      v-model="product.bullet_4"
                      type="text"
                      class="form-control"
                      id="bullet_1" />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="carton_width">Bullet Point 5</label>
                    <input
                      v-model="product.bullet_5"
                      type="text"
                      class="form-control"
                      id="bullet_2" />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="carton_height">Bullet Point 6</label>
                    <input
                      v-model="product.bullet_6"
                      type="text"
                      class="form-control"
                      id="bullet_3" />
                  </div>
                </div>

                <button
                  @click.prevent="save_product"
                  type="submit"
                  class="btn btn-success mr-2">
                  Save
                </button>
                <router-link
                  :to="{
                    name: 'Products',
                  }"
                  class="btn btn-secondary">
                  Cancel
                </router-link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import Countries from "../assets/Countries.json";
import { db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import { ArrowLeftIcon } from "vue-feather-icons";
import VueBarcode from "vue-barcode";

export default {
  name: "ProductEdit",
  data() {
    return {
      countries: Countries,
      product: {
        eta: "",
        barcode: "-",
        pick_location: "-",
        bulk_location_1: "-",
        bulk_location_2: "-",
        unit_length: 0,
        unit_width: 0,
        unit_height: 0,
        unit_weight: 0,
        pack_barcode: "-",
        pack_qty: 0,
        pack_length: 0,
        pack_width: 0,
        pack_height: 0,
        pack_weight: 0,
        inner_barcode: "-",
        inner_qty: 0,
        inner_length: 0,
        inner_width: 0,
        inner_height: 0,
        inner_weight: 0,
        carton_barcode: "-",
        carton_qty: 0,
        carton_length: 0,
        carton_width: 0,
        carton_height: 0,
        carton_weight: 0,
      },
    };
  },
  components: { basicLayout, ArrowLeftIcon, barcode: VueBarcode },
  firestore() {
    if (this.$route.params.product_id) {
      return {
        product: db.collection("products").doc(this.$route.params.product_id),
      };
    }
  },

  methods: {
    save_product() {
      const product = this.product;
      db.collection("products").doc(product.id).update(product);
      this.$router.push("/products");
    },
  },
};
</script>

<style scoped>
form table {
  font-size: 0.8rem;
}
.form-control-x-sm {
  height: calc(1.2em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.2rem;
}
.list-group-item {
  padding: 0.25rem 0.75rem;
}
textarea {
  width: 100%;
  height: 250px;
}

hr {
  margin-top: 2rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.3);
}
</style>