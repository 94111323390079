<template>
  <basicLayout>
    <h5>LABEL PRINTER</h5>
    <div class="col">
      <div class="custom-control custom-switch mb-2">
        <input
          v-model="print"
          type="checkbox"
          class="custom-control-input"
          id="customSwitch1"
        />
        <label class="custom-control-label" for="customSwitch1"
          >Print label on scan</label
        >
      </div>
    </div>
    <div class="col-xl-9 col-sm-12">
      <div class="card">
        <div class="card-body text-center p-0">
          <h2>SCAN A BARCODE</h2>
          <barcode v-bind:value="123456789">
            Show this if the rendering fails.
          </barcode>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import BarcodeToast from "@/components/BarcodeToast";
import ScanPopup from "@/components/ScanPopup";
import sort_mixins from "@/mixins/sort_mixins";
import filter_mixins from "@/mixins/filter_mixins";
import JSPM from "jsprintmanager/JSPrintManager";
import VueBarcode from "vue-barcode";

export default {
  name: "scan",

  data() {
    return {
      show: true,
      print: false,
      barcode: "",
      products: [],
      scanned_product: ""
    };
  },

  components: { basicLayout, barcode: VueBarcode },
  mixins: [sort_mixins, filter_mixins],

  firestore() {
    return {
      products: db.collection("products")
    };
  },

  created() {
    window.addEventListener("keypress", this.scanner);
    JSPM.JSPrintManager.license_url =
      "https://portal.freshoffice.co.nz/api/jspm";
    JSPM.JSPrintManager.auto_reconnect = true;
    JSPM.JSPrintManager.start();
  },
  destroyed() {
    window.removeEventListener("keypress", this.scanner);
  },

  // ================================================================
  methods: {
    scanner(e) {
      console.log("E KEY: " + e.key);

      if (e.key != "Enter") {
        this.barcode = this.barcode + e.key;
        console.log(this.barcode);
      } else {
        console.log("BARCODE: " + this.barcode);
        let product = this.products.find(p => {
          return (
            this.barcode === p.barcode ||
            this.barcode === p.inner_barcode ||
            this.barcode === p.carton_barcode
          );
        });

        console.log(product);

        const product_id = product && product.id;
        if (!product_id) {
          const content = {
            component: BarcodeToast,
            props: {
              code: this.barcode,
              description: "that barcode is not recognised"
            }
          };
          this.$toast.error(content, {
            position: "top-right",
            timeout: 3000
          });
          console.log("that barcode is not recognised");
          this.barcode = "";
        } else {
          console.log("product_id: " + product_id);
          this.scanned_product = product;
          const content = {
            component: ScanPopup,
            props: {
              scanned_product: this.scanned_product
            },
            listeners: {
              edit_product: () => {
                this.$router.push({
                  name: "ProductEdit",
                  params: { product_id: this.scanned_product.id }
                });
              }
            }
          };
          this.$toast.success(content, {
            position: "top-center",
            timeout: 4000
          });

          if (this.print) {
            this.print_label(product);
          }
          this.barcode = "";
          setTimeout(function() {
            this.scanned_product = "";
          }, 3000);
        }
        return;
      }
    },
    print_label(product) {
      console.log("printing");
      console.log(product.id);
      let zpl;
      console.log(product.category4);
      if (product.category4 === "BRAUSE" || product.category4 === "HERBIN") {
        zpl = `
        ^XA
FWR
^FX GB command parameters:
^FX - width (number)
^FX - height (number)
^FX - thickness (number)
^FX - color (B/W)
^FX - rounding (number, 0-8)

^FO270,30^A0R,30^FD${product.code}^FS
^FO260,160^FB250,2,0,L,0^A0R,20^FD${product.description}^FS

^FX BC command parameters:
^FX - orientation (N/R/I/B)
^FX - height (number)
^FX - showHumanReadableText (Y/N)
^FX - showTextAbove (Y/N)
^FX - addUccCheckDigit (Y/N)
^FX - mode (A/U/D/N)
^BY2,2,50
^FO200,40^BCR^FD${product.barcode}^FS
^XZ
`;
      } else {
        zpl = `

^XA
FWR
^FX GB command parameters:
^FX - width (number)
^FX - height (number)
^FX - thickness (number)
^FX - color (B/W)
^FX - rounding (number, 0-8)
^FO5,35^GB420,790,3,B,0^FS
^FO280,35^GB60,790,3,B,0^FS
^FO5,35^GB60,790,3,B,0^FS
^FO5,420^GB60,1,3,B,0^FS
^FO340,420^GB85,1,3,B,0^FS

^FO350,60^A0R,50^FD${product.code}^FS
^FO360,450^A0R,30^FD${product.supplier_code || ""}^FS
^FO280,60^FB750,2,0,L,0^A0R,24^FD${product.description}^FS

^FO20,450^A0R,30^FD${product.location || ""}^FS

^FX BC command parameters:
^FX - orientation (N/R/I/B)
^FX - height (number)
^FX - showHumanReadableText (Y/N)
^FX - showTextAbove (Y/N)
^FX - addUccCheckDigit (Y/N)
^FX - mode (A/U/D/N)
^BY3,2,150
^FO105,80^BCR^FD${product.barcode}^FS
^XZ`;
      }
      var cpj = new JSPM.ClientPrintJob();
      cpj.clientPrinter = new JSPM.DefaultPrinter();
      cpj.printerCommands = zpl;
      cpj.sendToClient();
    }
  }
};
</script>

<style scoped>
.border-3 {
  border-width: 3px !important;
}
.card {
  border-width: 3px !important;
}
.card-bounce {
  transform: scale(1);
  /* animation: bounce 0.5s; */
  box-shadow: 0 0 0 0 rgb(65, 190, 76);
  transform: scale(1);
  animation: bounce 1.5s;
}

@keyframes bounce {
  0% {
    transform: scale(1);
    background-color: rgba(51, 217, 65, 0.7);
    box-shadow: 0 0 0 0 rgba(51, 217, 65, 0.7);
  }

  70% {
    transform: scale(1.025);
    box-shadow: 0 0 0 10px rgba(51, 217, 65, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(51, 217, 65, 0);
  }
}
.my-toast {
  font-size: 5em;
}

@media print {
  body * {
    visibility: hidden;
    size: landscape;
  }

  .printable-table,
  .printable-table * {
    visibility: visible;
    size: landscape;
  }
  th.no-print,
  td.no-print {
    display: none;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: collapse;
  }
  tr {
    break-after: auto;
  }

  .printable-table {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 100px;
    size: landscape;
  }
  table {
    font-size: 1em;
  }
}
@media screen {
  .print-only {
    visibility: hidden;
    height: 0;
  }
}
</style>