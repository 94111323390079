<template>
  <basicLayout>
    <div class="p-2">
      <h5>FTP CONNECTIONS</h5>
      <div class="card">
        <div class="card-body p-3">
          <h4>Create Webhook</h4>
          <form @submit.prevent="createWebhook">
            <div class="form-group">
              <label for="Name">Scope</label>
              <input
                v-model="webhook.scope"
                type="text"
                class="form-control"
                placeholder="e.g. store/order/*"
                required
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">Email address</label>
              <input
                v-model="webhook.destination"
                type="text"
                class="form-control"
                placeholder="e.g. https://portal.freshoffice.co.nz/webhooks"
                required
              />
            </div>
            <button type="submit" class="btn btn-secondary">Create</button>
          </form>
        </div>
      </div>
      {{ dir }}
      <div class="mt-3">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th class="text-left" scope="col">ID</th>
              <th class="text-left" scope="col">Scope</th>
              <th class="text-left" scope="col">Destination</th>
              <th class="text-left" scope="col">Active</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-for="webhook in webhooks" :key="webhook.id">
            <tr>
              <td class="text-left">{{ webhook.id }}</td>
              <td class="text-left">{{ webhook.scope }}</td>
              <td class="text-left">{{ webhook.destination }}</td>
              <td class="text-left">{{ webhook.is_active }}</td>
              <td>
                <a @click="deleteWebhook(webhook.id)">x</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <button @click="geFtpDir" class="btn btn-outline-secondary" type="button">
        geFtpDir
      </button>

      <div class="custom-file">
        <input
          type="file"
          class="custom-file-input"
          id="customFile"
          @change="upload_key"
        />
        <label class="custom-file-label" for="customFile">Choose file...</label>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { fb, storage } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";

export default {
  name: "orders",
  data() {
    return {
      dir: [],
      webhooks: [],
      webhook: {}
    };
  },
  components: { basicLayout },

  methods: {
    upload_key(event) {
      const key_file = event.target.files[0];
      const storageRef = storage.ref("keys/" + key_file.name);
      console.log("uploading...");
      storageRef.put(key_file).then(console.log("Uploaded."));
    },
    geFtpDir() {
      const getFtpDir = fb.httpsCallable("sftp");

      const data = {
        bucketName: "freshofficeportal.appspot.com",
        fileName: "fresh-key.txt",
        dirPath: "/suppliers/FRES Loyalty SFTP"
      };

      console.log(JSON.stringify(data));

      getFtpDir(data).then(result => {
        console.log(JSON.stringify(result));
        this.dir = result.data;
      });
    }
  },

  computed: {}
};
</script>