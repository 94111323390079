<template>
  <basicLayout>
    <div class="row">
      <div class="col-xl-8">
        <h5>CSV ORDER UPLOAD</h5>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6 form-group">
                <div class="custom-file">
                  <input
                    @change="file_picker"
                    type="file"
                    class="custom-file-input"
                    id="customFile" />
                  <label
                    class="custom-file-label"
                    for="customFile"
                    >Choose file</label
                  >
                </div>
              </div>

              <div class="col-xl-6 form-group">
                <div
                  v-cloak
                  @drop.prevent="drag"
                  @dragover.prevent>
                  <input
                    class="form-control progress-bar-striped alert-dark"
                    placeholder="...or drag file here" />
                </div>
              </div>
            </div>
            <div
              v-if="upload_file_name"
              class="row">
              <div class="col-xl-6 text-right">
                <small>{{ upload_file_name }}</small>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <label for="upload_template">Saved Templates</label>
                  <v-select
                    v-model="upload_template"
                    :options="template_options"
                    :selectOnTab="true"
                    :clearable="false"></v-select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="Name">Name Code</label>
                  <small class="form-text text-muted"
                    >Use this input if the csv upload file does not contain a
                    column for Name Code</small
                  >
                  <input
                    v-model="name_code"
                    type="text"
                    class="form-control"
                    id="name"
                    required />
                </div>

                <div class="form-group">
                  <label for="Name">PO Number</label>
                  <small class="form-text text-muted"
                    >Use this input if the csv upload file does not contain a
                    column for PO Number</small
                  >
                  <input
                    v-model="po_number"
                    type="text"
                    class="form-control"
                    id="name"
                    required />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="upload_file.length"
            class="card-footer">
            <div class="row">
              <div class="col">
                <button
                  v-if="upload_template"
                  @click.prevent="upload_order"
                  type="submit"
                  class="btn btn-sm btn-outline-success">
                  Upload Orders
                </button>
              </div>
              <div class="col text-right">
                <button
                  @click="show_map = !show_map"
                  class="btn btn-sm btn-outline-secondary ml-1">
                  {{ show_map ? "Hide Map" : "Show Map" }}
                </button>
                <button
                  v-if="upload_template"
                  @click.prevent="preview_upload"
                  type="submit"
                  class="btn btn-sm btn-outline-secondary ml-1">
                  Preview Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="show_map"
      class="row mt-2"
      style="font-size: 0.8em">
      <div class="col-8">
        <div class="card">
          <div class="card-header"><h6>Field Map</h6></div>
          <div class="card-body">
            <div
              v-for="field in upload_template.field_map"
              :key="field.id">
              <div class="form-group">
                <label :for="field.label">{{ field.label }}</label>
                <v-select
                  placeholder="maps to..."
                  v-model="field.maps_to"
                  multiple
                  :options="upload_fields"
                  :selectOnTab="true"></v-select>
              </div>
            </div>
          </div>

          <div class="card-footer">
            <div class="row align-items-end">
              <div
                v-if="upload_file.length && !upload_template.id"
                class="col">
                <p>Save this Template As</p>
                <form class="form-inline">
                  <label
                    class="sr-only"
                    for="template_name"
                    >Name</label
                  >
                  <input
                    v-model="upload_template.label"
                    type="text"
                    class="form-control form-control-sm"
                    id="template_name"
                    placeholder="Template Name" />
                  <button
                    @click.prevent="save_template"
                    type="submit"
                    class="btn btn-sm btn-outline-secondary ml-2">
                    Save
                  </button>
                </form>
              </div>
              <div
                v-if="upload_file.length && upload_template.id"
                class="col">
                <p>This Template: {{ upload_template.label }}</p>
                <button
                  @click.prevent="update_template"
                  type="submit"
                  class="btn btn-sm btn-outline-secondary">
                  Update Template
                </button>
              </div>
              <div class="col text-right">
                <button
                  @click="show_map = !show_map"
                  class="btn btn-sm btn-outline-secondary">
                  {{ show_map ? "Hide Map" : "Show Map" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="upload_array.length && show_preview"
      class="row mt-2">
      <div class="col-8">
        <div class="card">
          <div class="card-header"><h6>Preview</h6></div>
          <div class="card-body">
            <div class="tableFixHead tableFixHeadShort">
              <table
                class="table table-bordered table-hover table-sm table-preview">
                <thead class="thead-dark">
                  <tr>
                    <th class="text-left">Order#</th>
                    <!-- <th class="text-left">Name</th> -->
                    <th class="text-left">Delivery Name</th>
                    <th class="text-left">Company</th>
                    <th class="text-left">Street</th>
                    <th class="text-left">Suburb</th>
                    <th class="text-left">City</th>
                    <th class="text-left">Postcode</th>
                    <th class="text-left">Items</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="line in upload_array"
                    :key="line.po_number">
                    <td class="text-left">
                      {{ line.po_number }}
                    </td>
                    <!-- <td class="text-left">
                      {{ line.first_name }} {{ line.last_name }}
                    </td> -->
                    <td class="text-left">
                      {{ line.delivery_address.first_name }}
                      {{ line.delivery_address.last_name }}
                    </td>
                    <td class="text-left">
                      {{ line.delivery_address.company }}
                    </td>
                    <td class="text-left">
                      {{ line.delivery_address.street_1 }}
                    </td>
                    <td class="text-left">
                      {{ line.delivery_address.street_2 }}
                    </td>
                    <td class="text-left">{{ line.delivery_address.city }}</td>
                    <td class="text-left">
                      {{ line.delivery_address.postcode }}
                    </td>
                    <td>
                      <table
                        class="clearfix table table-borderless table-sm table-preview">
                        <tr
                          v-for="item in line.order_lines"
                          :key="item.stock_code"
                          class="w-auto">
                          <td class="w-15">{{ item.stock_code }}</td>
                          <td class="w-75">{{ item.description }}</td>
                          <td class="w-10 text-right">{{ item.quantity }}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col">
                <button
                  @click.prevent="upload_order"
                  type="submit"
                  class="btn btn-sm btn-outline-success">
                  Upload Orders
                </button>
              </div>

              <div class="col text-right">
                <button
                  @click="show_preview = false"
                  class="btn btn-sm btn-outline-secondary">
                  Hide Preview
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import basicLayout from "@/components/basicLayout";
import Papa from "papaparse";
import { actions } from "@/store";
import { fb, db } from "@/firebaseConfig";
import addy_mixin from "@/mixins/addy_mixin";

const default_template = {
  id: "",
  default: true,
  label: "Default",
  field_map: [
    { id: "name_code", label: "Name Code", maps_to: "" },
    {
      id: "key",
      label: "Unique Key (required for multi-line orders",
      maps_to: "",
    },
    { id: "po_number", label: "PO Number", maps_to: "" },
    { id: "email", label: "Email", maps_to: "" },
    { id: "delivery_first_name", label: "Delivery First Name", maps_to: "" },
    { id: "delivery_last_name", label: "Delivery Last Name", maps_to: "" },
    { id: "delivery_email", label: "Delivery Email", maps_to: "" },
    { id: "delivery_company", label: "Delivery Company", maps_to: "" },
    { id: "delivery_address1", label: "Delivery Address 1", maps_to: "" },
    { id: "delivery_address2", label: "Delivery Address 2", maps_to: "" },
    { id: "delivery_city", label: "Delivery City", maps_to: "" },
    { id: "delivery_postcode", label: "Delivery Postcode", maps_to: "" },
    { id: "item_stock_code", label: "Item Code", maps_to: "" },
    { id: "item_description", label: "Item Desciption", maps_to: "" },
    { id: "item_quantity", label: "Item Quantity", maps_to: "" },
    { id: "item_weight", label: "Item Weight", maps_to: "" },
  ],
};
export default {
  name: "order_upload",
  data: function () {
    return {
      name_code: "",
      po_number: "",
      upload_file_name: "",
      upload_file: {},
      upload_fields: [],
      upload_templates: [{ label: "", field_map: [] }],
      upload_template: default_template,
      upload_array: [],
      field_map: {},
      show_map: false,
      show_preview: false,
    };
  },
  firestore() {
    return {
      upload_templates: db.collection("upload_templates"),
    };
  },
  components: {
    basicLayout,
  },
  mixins: [addy_mixin],
  methods: {
    drag(event) {
      this.upload(event.dataTransfer.files[0]);
      event.target.value = "";
    },
    file_picker(event) {
      // console.log("file picker");
      const upload_file = event.target.files[0];
      this.upload_file_name = upload_file.name;
      this.upload(upload_file);

      event.target.value = "";
    },
    upload(file) {
      const pre_process_upload = this.pre_process_upload;
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete(results) {
          pre_process_upload(results);
        },
      });
    },

    pre_process_upload: function (upload_data) {
      this.upload_fields = upload_data.meta.fields;
      this.upload_file = upload_data.data;
    },

    preview_upload: function () {
      this.process_order();
      this.show_preview = true;
    },

    process_order: async function () {
      this.field_map = new Map(
        this.upload_template.field_map.map((i) => [i.id, i.maps_to])
      );

      let join_fields = this.join_fields;

      const upload_array = this.upload_file

        .map((line, index) => {
          // function join_fields(field_id) {
          //   let join = " ";
          //   if (field_id === "key") {
          //     join = "_";
          //   }
          //   return (
          //     field_id &&
          //     field_map.get(field_id) &&
          //     field_map
          //       .get(field_id)
          //       .map(f => line[f])
          //       .join(join)
          //   );
          // }

          let delivery_first_name = join_fields(line, "delivery_first_name");
          let delivery_last_name = join_fields(line, "delivery_last_name");

          if (!delivery_last_name) {
            delivery_last_name = delivery_first_name
              .split(" ")
              .slice(-1)
              .join(" ");

            delivery_first_name = delivery_first_name
              .split(" ")
              .slice(0, -1)
              .join(" ");
          }

          const order_object = {
            key: join_fields(line, "key"),
            name_code: join_fields(line, "name_code"),
            po_number: join_fields(line, "po_number"),
            order_total: join_fields(line, "order_total"),

            delivery_address: {
              first_name: delivery_first_name,
              last_name: delivery_last_name,
              name: `${delivery_first_name} ${delivery_last_name}`,
              company: join_fields(line, "delivery_company"),
              email: join_fields(line, "delivery_email"),
              street_1: join_fields(line, "delivery_address1"),
              street_2: join_fields(line, "delivery_address2"),
              city: join_fields(line, "delivery_city"),
              postcode: join_fields(line, "delivery_postcode").toString(),
            },
            order_lines: [
              {
                id: `${join_fields(line, "key")}_${index}`,
                stock_code: join_fields(line, "item_stock_code"),
                description: join_fields(line, "item_description"),
                quantity: Number(join_fields(line, "item_quantity")),
                weight: Number(join_fields(line, "item_weight")),
              },
            ],
          };
          // console.log(JSON.stringify(order_object));
          return order_object;
        })

        .reduce((result, line) => {
          const existing = result.find((r) => r.key === line.key);
          if (existing) {
            const order_lines = {
              id: line.order_lines[0].id,
              stock_code: line.order_lines[0].stock_code,
              description: line.order_lines[0].description,
              quantity: line.order_lines[0].quantity,
              weight: line.order_lines[0].weight,
            };
            existing.order_lines.push(order_lines);
          } else {
            result.push(line);
          }
          return result;
        }, [])

        .map((line) => {
          line.name_code = line.name_code || this.name_code;
          line.po_number = line.po_number || this.po_number;
          return line;
        });

      this.upload_array = upload_array;
      return;
    },

    upload_order: async function () {
      actions.setLoading(true);
      const order_to_moneyworks = fb.httpsCallable("order_to_moneyworks");

      let order_promises = this.upload_array.map(async (order_object) => {
        console.log(JSON.stringify(order_object));
        let mw_res = await order_to_moneyworks(order_object);
        console.log(JSON.stringify(mw_res.data));
      });

      await Promise.all(order_promises);

      actions.setLoading(false);

      // console.log(order_to_moneyworks_res);
      // if (order_to_moneyworks_res.data.success) {
      //   this.$router.push({ name: "DropShip" });

      //   this.$toast.success("Order Imported", {
      //     position: "top-right",
      //     timeout: 2000
      //   });
      // } else {
      //   this.$toast.error(
      //     "Order did not Import: " + order_to_moneyworks_res.error,
      //     {
      //       position: "top-right",
      //       timeout: 2000
      //     }
      //   );
      // }
      // actions.setLoading(false);
      // return;
    },

    join_fields(line, field_id) {
      console.log("JOINING FIELDS " + field_id);
      let field_map = this.field_map;
      let join = " ";
      if (field_id === "key") {
        join = "_";
      }

      return (
        field_id &&
        field_map.get(field_id) &&
        field_map
          .get(field_id)
          .map((f) => line[f])
          .join(join)
      );
    },

    save_template: async function () {
      if (this.upload_template.label === "Default") {
        alert("Don't name your saved template 'Default'");
        return;
      }
      db.collection("upload_templates").add({
        created: new Date(),
        label: this.upload_template.label,
        field_map: this.upload_template.field_map,
      });
    },

    update_template: async function () {
      db.collection("upload_templates").doc(this.upload_template.id).update({
        updated: new Date(),
        field_map: this.upload_template.field_map,
      });
    },
  },
  computed: {
    template_options() {
      const template_array = [...this.upload_templates];
      template_array.push(default_template);
      return template_array;
    },
  },
};
</script>
<style scoped>
.table-preview {
  font-size: 0.8em;
}
</style>     