<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col"><h5>EDI IMPORTS</h5></div>
              <div class="col text-right">
                <button @click="ftp_fetch" class="btn btn-outline-success">
                  Refresh
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="tableFixHead tableFixHeadLong">
              <table class="table table-bordered table-hover table-sm">
                <thead class="thead-dark">
                  <tr>
                    <th class="text-left" scope="col">Date</th>
                    <th class="text-left" scope="col">Name Code</th>
                    <th class="text-left" scope="col">File Name</th>
                    <th class="text-left" scope="col">Imported</th>
                    <th class="text-left" scope="col">Order ID</th>
                    <th class="text-left" scope="col">View</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="order in orders" :key="order.id">
                    <td class="text-left">
                      {{ order.created | moment_short }}
                    </td>
                    <td class="text-left">
                      {{ order.name_code }}
                    </td>
                    <td class="text-left">
                      {{ order.file_name }}
                    </td>
                    <td class="text-left">
                      {{ order.imported }}
                    </td>
                    <td class="text-left">
                      {{ order.new_order_id }}
                    </td>
                    <td>
                      <span
                        @click="view_order(order)"
                        class="badge"
                        :class="
                          order.imported ? 'badge-success' : 'badge-warning'
                        "
                        >{{ order.imported }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="selected_order.po_number" class="row mt-2">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-6 h5">
                <span class="text-muted">FILE:</span>
                {{ selected_order.file_name }}
              </div>
              <div class="col-3 h5">
                <span class="text-muted">NAME:</span>
                {{ selected_order.name_code }}
              </div>
              <div class="col-3 h5">
                <span class="text-muted">PO:</span>
                {{ selected_order.po_number }}
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="col">
              <div class="row">
                <div class="tableFixHead tableFixHeadLong m-3">
                  <table class="table table-bordered table-hover table-sm">
                    <thead class="thead-dark">
                      <tr>
                        <th class="text-left" scope="col">Code</th>
                        <th class="text-left" scope="col">Title</th>
                        <th class="text-left" scope="col">Barcode</th>
                        <th class="text-left" scope="col">Quantity</th>
                        <th class="text-left" scope="col">Price</th>
                        <th class="text-left" scope="col">Line Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="line in selected_order.order_lines"
                        :key="line.line_sequence_number"
                      >
                        <td class="text-left">
                          {{ line.stock_code }}
                        </td>
                        <td class="text-left">
                          {{ line.import_description }}
                        </td>
                        <td class="text-left">
                          {{ line.barcode }}
                        </td>
                        <td class="text-right">
                          {{ line.quantity }}
                        </td>
                        <td class="text-right">
                          {{ line.import_price }}
                        </td>
                        <td class="text-right">
                          {{ line.quantity * line.import_price }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-8">
                  <h6>RAW FILE CONTENTS</h6>
                  <textarea
                    v-model="selected_order.edi_content"
                    spellcheck="false"
                  ></textarea>
                </div>

                <div class="col-4">
                  <h6>IMPORTED JSON FILE</h6>
                  <textarea
                    v-model="json_order_lines"
                    spellcheck="false"
                  ></textarea>
                </div>
              </div>
              <!-- <button type="button" class="btn btn-secondary">Close</button>
              <button type="button" class="btn btn-primary">Resubmit</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fb, db } from "@/firebaseConfig";
// import EDIModal from "@/components/EDIModal";
import filter_mixins from "@/mixins/filter_mixins";
export default {
  name: "edi_logs",
  data() {
    return {
      orders: [],
      selected_order: {},
      json_order_lines: "",
    };
  },

  watch: {
    selected_order: function (newValue) {
      try {
        let json_order_lines = JSON.parse(newValue);
        this.selected_order.order_lines = json_order_lines;
      } catch (err) {
        console.log("seems to be invalid json: " + err);
        // reset:
        this.json_order_lines = JSON.stringify(
          this.selected_order.order_lines,
          undefined,
          2
        );
      }
    },
  },

  methods: {
    view_order(order) {
      this.selected_order = order;
    },
    ftp_fetch() {
      console.log("ftp_fetch");
      const ftp_fetch = fb.httpsCallable("ftp_fetch");
      ftp_fetch({ text: "yoyoyoyo" }).then((result) => {
        console.log(JSON.stringify(result));
      });
    },
  },
  // components: { EDIModal },
  mixins: [filter_mixins],

  firestore: {
    orders: db.collection("edi").limit(200),
  },
};
</script>

<style scoped>
textarea {
  font-family: Consolas, Arial, Tahoma, sans-serif;
  font-size: 0.8em;
  width: 100%;
  height: 30vh;
}
</style>

