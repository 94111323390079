<template>
  <basicLayout>
    <div class="p-2">
      <h5>CUSTOMERS</h5>
      <div class="row">
        <div class="col-xl-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Search...</span>
            </div>
            <input
              @input="debounce_search"
              :value="search"
              type="text"
              class="form-control"
              placeholder="Code, Name etc." />
            <div class="input-group-append">
              <button
                @click="clear_search"
                class="btn btn-outline-secondary"
                type="button">
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-6">
          <div class="btn-toolbar">
            <button
              @click="with_rules_filter"
              type="button"
              class="btn btn-sm btn-outline-secondary mr-2">
              {{ rules_filter ? "Show All" : "With Rules" }}
            </button>
          </div>
          <hr />
          <div class="row mb-2">
            <div class="col"></div>
            <div class="col text-right">
              <b>Showing: {{ this.filtered.length }}</b> of
              <b>{{ this.display_names.length }}</b>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="tableFixHead">
            <table class="table table-bordered table-hover table-sm">
              <thead class="thead-dark">
                <tr>
                  <th>
                    <!-- <input type="checkbox" v-model="selectAll" @click="select" /> -->
                  </th>
                  <th @click="sort_by('code')">Code</th>
                  <th @click="sort_by('name')">Name</th>
                  <th @click="sort_by('email')">Emails</th>
                  <th @click="sort_by('bc_customer_id')">BCID</th>
                  <th @click="sort_by('group')">Group</th>
                  <th>Rules</th>
                  <th>Group Rules</th>
                  <!-- <th></th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="name in filtered"
                  :key="name.id">
                  <td class="text-center">
                    <router-link
                      :to="{
                        name: 'CustomerEdit',
                        params: { customer_id: name.id },
                      }">
                      <edit-icon
                        size="1x"
                        class="custom-class"></edit-icon>
                    </router-link>
                  </td>
                  <td>{{ name.code }}</td>
                  <td>{{ name.name }}</td>
                  <td>
                    <span>{{ name.email }}</span>
                    <span v-show="name.email2"
                      >&nbsp;/&nbsp;{{ name.email2 }}</span
                    >
                  </td>
                  <td>
                    {{
                      name.bc_customer_ids &&
                      name.bc_customer_ids.length &&
                      name.bc_customer_ids.join(", ")
                    }}
                  </td>
                  <td @dblclick="filter_by(name.group)">{{ name.group }}</td>
                  <td class="text-right">
                    <router-link
                      :to="{ name: 'Rules', params: { name_code: name.code } }"
                      >{{ total_rules(name.code) }}</router-link
                    >
                  </td>
                  <td class="text-right">
                    <router-link
                      :to="{ name: 'Rules', params: { name_code: name.group } }"
                      >{{ total_rules(name.group) }}</router-link
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <Pagination
            v-if="pages"
            :pages="pages"
            :selected_page="selected_page"
            v-on:updatePage="updatePage" />
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import { orderBy } from "lodash";
import basicLayout from "@/components/basicLayout";
import Pagination from "@/components/Pagination";
import filter_mixins from "@/mixins/filter_mixins";
// import sort_mixins from "@/mixins/sort_mixins";
import { EditIcon } from "vue-feather-icons";

const names = db.collection("names");

export default {
  name: "customers",
  data() {
    return {
      rules: [],
      debounce: null,
      display_names: [],
      sort_column: ["code"],
      sort_order: "asc",
      page_limit: 50,
      search: "",
      verbose: false,
      rules_filter: true,
      selected_page: 1,
    };
  },
  components: { basicLayout, Pagination, EditIcon },
  mixins: [filter_mixins],

  firestore: {
    rules: db.collection("rules").where("prevailing", "==", true),
  },

  created() {
    this.search = this.$route.params.filter || "";
  },

  methods: {
    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 200);
    },
    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    updatePage(value) {
      this.selected_page = value;
    },

    filter_by(filter) {
      this.search = filter;
    },

    clear_search() {
      this.search = "";
    },
    total_rules(code) {
      const applicable_rules = this.rules.filter((r) => {
        return (
          code &&
          r.name_code.toString().toUpperCase() == code.toString().toUpperCase()
        );
      });
      return applicable_rules.length;
    },

    with_rules_filter() {
      this.rules_filter = !this.rules_filter;
    },
  },
  computed: {
    // pre_filtered_names() {
    //   return this.names.filter(name => {
    //     return name.has_rule == true;
    //   });
    // },

    // display_names() {
    //   return this.rules_filter ? this.pre_filtered_names : this.names;
    // },

    filtered() {
      let visible_customers = this.display_names.slice();
      let search = this.search;

      if (this.sort_column) {
        visible_customers = orderBy(
          visible_customers,
          (item) => item[this.sort_column] || "",
          this.sort_order
        );
      }

      if (!search) {
        let start = (this.selected_page - 1) * this.page_limit;
        let end = start + this.page_limit;
        visible_customers = visible_customers.slice(start, end);
      }

      visible_customers = visible_customers.filter((customer) => {
        const s = Object.values(customer);
        const su = s.toString().toUpperCase();
        return su.match(search.toString().toUpperCase());
      });

      return visible_customers;
    },
    pages() {
      const pages_array = this.search ? this.filtered : this.display_names;
      return Math.ceil(pages_array.length / this.page_limit);
    },
  },
  watch: {
    rules_filter: {
      immediate: true,
      handler(rules_filter) {
        if (rules_filter) {
          this.$bind("display_names", names.where("has_rule", "==", true));
        } else {
          this.$bind("display_names", names);
        }
      },
    },
    search: function (newSearch) {
      if (newSearch && newSearch.length > 0) {
        this.selected_page = 1;
      }
    },
  },
};
</script>

<style scoped>
</style>