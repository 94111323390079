<template>
  <basicLayout>
    <div class="p-2">
      <h5>PRICE LIST</h5>
      <div class="row">
        <div class="col-xl-6 mt-2 mt-xl-0">
          <v-select
                    :options="names_search"
                    v-model="name"></v-select>
        </div>

        <div class="col-xl-6 mt-2 mt-xl-0">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Search...</span>
            </div>
            <input
                   @input="debounce_search"
                   @change="with_rules = false"
                   v-model="search"
                   type="text"
                   class="form-control"
                   placeholder="PO, SO, Name, or Carrier" />
            <div class="input-group-append">
              <button
                      @click="clear_search"
                      class="btn btn-outline-secondary"
                      type="button">
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="btn-toolbar">
            <a
               @click="download_csv('all', $event)"
               :disabled="this.customer_price_list.length === 0"
               class="btn btn-sm btn-outline-secondary mr-2"
               role="button">Download All</a>
            <a
               @click="download_csv('selected', $event)"
               :disabled="this.customer_price_list.length === 0"
               class="btn btn-sm btn-outline-secondary mr-2"
               role="button">Download Selected</a>
            <button
                    @click="verbose = !verbose"
                    type="button"
                    class="btn btn-sm btn-outline-secondary mr-2">
              {{ verbose ? "Less Detail" : "More Detail" }}
            </button>

            <button
                    v-show="name.has_rule"
                    @click="toggle_with_rules"
                    type="button"
                    class="btn btn-sm btn-outline-secondary mr-2">
              {{ with_rules ? "All" : "With Rules" }}
            </button>
            <router-link
                         class="btn btn-sm btn-outline-secondary mr-2"
                         :to="{ name: 'FeedFields' }">Edit Fields</router-link>
          </div>
        </div>
      </div>

      <hr />

      <div class="row mb-2 mt-2">
        <div class="col-xl-12">
          <p v-if="name">
            <b>Code:</b>
            {{ name.code }} &nbsp;&nbsp;
            <b>Price Level:</b>
            {{ name.productpricing }}
          </p>
        </div>
        <div class="col text-right">
          <b>Showing: {{ this.filtered.length }}</b>
          <br />
          <b>Selected: {{ this.selected.length }}</b> of
          <b>{{ this.filtered.length }}</b>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="tableFixHead">
            <table class="table table-bordered table-hover table-sm">
              <thead class="thead-dark">
                <tr>
                  <th>
                    <input
                           type="checkbox"
                           v-model="selectAll"
                           @click="select" />
                  </th>
                  <th @click="sort_string(products, 'code')">Code</th>
                  <th @click="sort_string(products, 'description')">Name</th>
                  <th
                      @click="sort_string(products, 'category1')"
                      class="w-10">
                    Cat 1
                  </th>
                  <th
                      @click="sort_string(products, 'category2')"
                      class="w-10">
                    Cat 2
                  </th>
                  <th
                      @click="sort_string(products, 'category3')"
                      class="w-10">
                    Cat 3
                  </th>
                  <th
                      v-if="verbose"
                      @click="sort_number(products, 'product_pricing')"
                      class="w-5">
                    Sell Price
                  </th>
                  <th
                      @click="sort_number(products, 'prevailing')"
                      class="w-5">
                    Prevailing
                  </th>

                  <th
                      v-if="verbose"
                      @click="sort_number(products, 'prevailing')"
                      class="w-5">
                    Rule
                  </th>
                </tr>
              </thead>
              <tbody
                     v-for="(product, i) in filtered"
                     :key="product.code">
                <tr :class="rowClass(product)">
                  <td>
                    <input
                           @click="selectOne($event, i)"
                           type="checkbox"
                           :value="product.code"
                           v-model="selected" />
                  </td>
                  <td>{{ product.code }}</td>
                  <td>{{ product.description }}</td>
                  <td @dblclick="filter_by(product.category1)">
                    {{ product.category1 }}
                  </td>
                  <td @dblclick="filter_by(product.category2)">
                    {{ product.category2 }}
                  </td>
                  <td @dblclick="filter_by(product.category3)">
                    {{ product.category3 }}
                  </td>
                  <td
                      v-if="verbose"
                      class="text-right">
                    {{ product[product_pricing] | currency }}
                  </td>
                  <td
                      class="text-right"
                      :class="price_type(product)">
                    {{ product.sell_price | currency }}
                  </td>
                  <td v-if="verbose && product.rules">
                    <router-link
                                 :to="{
                                   name: 'Rules',
                                   params: { rule_id: product.rules.product_code },
                                 }">{{ product.rules.name_code }}:
                      {{ product.rules.start_date }}-{{
                        product.rules.end_date
                      }}</router-link>
                  </td>
                  <td v-if="verbose && !product.rules">-</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import moment from "moment";
import Papa from "papaparse";
import { db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import filter_mixins from "@/mixins/filter_mixins";
import sort_mixins from "@/mixins/sort_mixins";

const rules = db.collection("rules");

export default {
  name: "pricelist",
  data() {
    return {
      names: [],
      products: [],
      applied_rules: [],
      fields: [],
      with_rules: false,
      name: "",
      search: "",
      sort_toggle: 1,
      selected: [],
      selectAll: false,
      savedIndex: null,
      verbose: false,
    };
  },
  components: { basicLayout },
  mixins: [sort_mixins, filter_mixins],

  firestore: {
    products: db.collection("products").where("hide", "==", false),
    names: db.collection("names"),
    fields: db.collection("feed_fields").orderBy("index"),
  },

  watch: {
    name: {
      immediate: true,
      handler(name) {
        if (name) {
          this.$bind(
            "applied_rules",
            rules
              .where("name_code", "array-contains-any", [name.code, name.group])
              // .where("prevailing", "==", true)
              .where("rule_type", "==", "everyday")
          );
        }
      },
    },
  },

  methods: {
    debounce_search(event) {
      this.message = null;
      console.log("You are typing");
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        console.log("You stopped typing");
        this.search = event.target.value;
      }, 400);
    },
    toggle_with_rules() {
      this.search = "";
      this.with_rules = !this.with_rules;
    },
    rowClass(product) {
      return { "alert-primary": this.selected.includes(product.code) };
    },
    selectOne(e, index) {
      console.log(index);
      const savedIndex = this.savedIndex;
      this.savedIndex = index;

      if (savedIndex === null || !e.shiftKey) return;
      if (index === savedIndex) return;

      const subset = this.filtered
        .slice(Math.min(index, savedIndex), Math.max(index, savedIndex) + 1)
        /* eslint-disable no-unused-vars */
        .map(({ code, ...rule }) => {
          return code;
        });

      if (e.currentTarget.checked) {
        if (!this.selected.length) {
          this.selected = subset;
        } else {
          this.selected = [...new Set(this.selected.concat(subset))];
        }
      } else {
        const subset_codes = new Set(subset);
        this.selected = this.selected.filter((sel) => {
          return !subset_codes.has(sel);
        });
      }
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.filtered) {
          this.selected.push(this.filtered[i].code);
        }
      }
    },
    filter_by(filter) {
      this.selected = [];
      this.selectAll = false;
      this.search = filter;
    },
    price_type(product) {
      return product.rules && "alert-primary";
    },

    clear_search() {
      this.selected = [];
      this.selectAll = false;
      this.search = null;
    },
    download_csv(selection, event) {
      // let csv_export;

      let download_products;

      if (selection === "selected") {
        download_products = this.customer_price_list.filter((p) =>
          this.selected.includes(p.id)
        );
      } else {
        download_products = this.customer_price_list;
      }

      let csv_export = download_products.map((line) => {
        const filtered_line = {};
        this.fields.forEach((field) => {
          filtered_line[field.display] = line[field.name];
        });
        return filtered_line;
      });

      const csv = Papa.unparse({
        fields: this.csv_display_fields,
        data: csv_export,
        quotes: true,
      });

      const csv_name = `${this.name.code}-price-list-${moment().format(
        "DD-MM-YYYY"
      )}.csv`;

      event.target.href = "data:text/csv," + encodeURIComponent(csv);
      event.target.download = csv_name;
    },
  },
  computed: {
    customer_products() {
      const products = this.products;
      const name = this.name;

      if (!name) {
        return [];
      }

      return products.filter((product) => {
        return (
          product.category1 !== "PENDING" &&
          (product.category1 !== "PRIVATE" ||
            product.category2.toUpperCase() === name.code.toUpperCase() ||
            product.category2.toUpperCase() === name.group.toUpperCase())
        );
      });
    },

    customer_price_list() {
      const name = this.name;
      if (!name) return [];
      const customer_products = this.customer_products;

      // ONLY APPLY EVERYDAY RULES - NOT PROMO RULES

      console.log(this.applied_rules)

      const applied_rules = this.applied_rules
        .filter(rule => rule.name_code)
        .reduce((result, item) => {
          const existing = result.find(rp => rp.product_code === item.product_code)

          if (existing) {
            if (item.name_code.includes(name.code)) {
              existing.price = item.price
            }
          } else {
            result.push(item);
          }
          return result;
        }, [])

        .map(rule => {
          return { product_code: rule.product_code, price: rule.price }
        });



      const product_pricing =
        `sellprice_${name.productpricing.toLowerCase()}` || "sellprice_a";

      // ================================================================
      // APPLY RULES (IF ANY) TO PRICE LIST - OR ELSE APPLY DEFAULT PRICE

      const price_list = customer_products.map((product) => {

        let product_rule =
          (applied_rules &&
            applied_rules.find(
              (rule) =>
              (rule.product_code.toString().toUpperCase() ===
                product.code.toString().toUpperCase()) // && rule.name_code?.includes(name.code)
            )) ||
          null;

        if (product_rule && product_rule.product_code === "IOC301B") {
          console.log("NAME RULE: " + JSON.stringify(product_rule))
        }

        // if (!product_rule) {
        //   product_rule =
        //     (applied_rules &&
        //       applied_rules.find(
        //         (rule) =>
        //           (rule.product_code.toString().toUpperCase() ===
        //             product.code.toString().toUpperCase()) && rule.name_code.includes(name.group)
        //       )) ||
        //     null;

        //   console.log("GROUP RULE: " + JSON.stringify(product_rule))
        // }

        if (product_rule && product_rule.product_code === "IOC301B") {
          console.log("PREVAILING RULE: " + JSON.stringify(product_rule))
        }

        const prevailing_price =
          (product_rule && product_rule.price) || product[product_pricing];

        // const prevailing_price =
        //   (product_rule && product_rule.price) || product[product_pricing];
        product.price_level;
        product.sell_price = prevailing_price;
        product.rules = product_rule;
        product.end_date = product_rule && product_rule.end_date;
        if (product.category4 === "ICON") {
          product.suppliers_code = product.code;
        }

        return product;
      });
      return price_list || [];
    },

    filtered() {
      const search = this.search;
      const customer_price_list = this.customer_price_list;

      if (this.with_rules) {
        return this.customer_price_list.filter((p) => {
          return p.rules;
        });
      }

      if (customer_price_list.length > 0 && !search) {
        return customer_price_list;
      }
      return this.customer_price_list.filter((product) => {
        const s = Object.values(product);
        const su = s.toString().toUpperCase();
        if (this.search) {
          return su.match(this.search.toString().toUpperCase());
        }
        return true;
      });
    },
    names_search() {
      return this.names.map((n) => {
        n.label = `${n.name} (${n.code})`;
        return n;
      });
    },
    product_pricing() {
      return (
        (this.name &&
          this.name.productpricing &&
          `sellprice_${this.name.productpricing.toLowerCase()}`) ||
        "sellprice_a"
      );
    },
    csv_display_fields() {
      return this.fields.filter((f) => f.csv).map((f) => f.display);
    },
  },
};
</script> 

<style scoped>
.container {
  width: 100% !important;
}
</style>