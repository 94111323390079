
export default {
  methods: {

    async address_search(query) {

      let url =
        "https://api.addy.co.nz/search?key=e7856cd0170f4205bffb4434ea80dd09&secret=e8cd6f81ba5c45de9f75cd3049c52129&max=10&s=" +
        query;
      let address_res = await fetch(url);
      let address_res_json = await address_res.json();
      return address_res_json
      // this.address_array = address_res_json.addresses;
    },
    async get_address_info(value) {
      console.log(JSON.stringify(value));

      let url =
        "https://api.addy.co.nz/address/" +
        value +
        "?key=e7856cd0170f4205bffb4434ea80dd09";

      let address_res = await fetch(url);
      let add = await address_res.json();

      console.log(add.type);

    }
  }
}