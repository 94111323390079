<template>
  <basicLayout>
    <div class="p-2">
      <h5>USERS</h5>
      <div class="row">
        <div class="col-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Search...</span>
            </div>
            <input
              v-model="search"
              type="text"
              class="form-control"
              placeholder="Name or Email"
            />
            <div class="input-group-append">
              <button
                @click="clear_search"
                class="btn btn-outline-secondary"
                type="button"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2">
          <router-link
            :to="{ name: 'UserCreate' }"
            type="button"
            class="btn btn-secondary btn-block"
            >Create User</router-link
          >
        </div>
      </div>
      <hr />
      <div>
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th class="text-left" scope="col">Name</th>

              <th class="text-left" scope="col">email</th>
              <th class="text-left" scope="col">role</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in filtered" :key="user.order_id">
              <td class="text-left">{{ user.name }}</td>

              <td class="text-left">
                <a :href="`mailto:${user.email}`">{{ user.email }}</a>
              </td>
              <td class="text-left">{{ user.role }}</td>
              <td class="text-center">
                <router-link
                  :to="{ name: 'UserProfile', params: { id: user.id } }"
                  title="edfit user"
                >
                  <edit-icon size="1x" class="custom-class"></edit-icon>
                </router-link>
              </td>
              <td class="text-center">
                <a @click="deleteUser(user.id)" title="delete user">
                  <trash-icon
                    size="1x"
                    class="custom-class text-danger"
                  ></trash-icon>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { fb, db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import { EditIcon, TrashIcon } from "vue-feather-icons";

export default {
  name: "users",
  data() {
    return {
      users: [],
      search: ""
    };
  },
  components: { basicLayout, EditIcon, TrashIcon },

  firestore() {
    return {
      users: db.collection("users")
    };
  },
  methods: {
    deleteUser(id) {
      const deleteUser = fb.httpsCallable("deleteUser");

      deleteUser({ id: id }).then(result => {
        console.log(JSON.stringify(result));
      });
    },

    clear_search() {
      this.search = "";
    }
  },
  computed: {
    filtered: function() {
      return this.users;
      // return this.users.filter(user => {
      //   const s = user.name + user.email;
      //   const su = s.toUpperCase();
      //   return su.match(this.search.toUpperCase());
      // });
    }
  }
};
</script>