<template>
  <basicLayout>
    <div class="p-2">
      <h5>PRODUCTS</h5>
      <div class="row">
        <div class="col-xl-3 mt-2 mt-xl-0">
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="customFile"
              @change="file_picker" />
            <label
              class="custom-file-label"
              for="customFile"
              >Choose file...</label
            >
          </div>
        </div>
        <div
          class="col-xl-3 mt-2 mt-xl-0"
          v-cloak
          @drop.prevent="drag"
          @dragover.prevent>
          <input
            class="form-control progress-bar-striped alert-dark"
            placeholder="...or drag file here" />
        </div>
        <div class="col-xl-6 mt-2 mt-xl-0">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Search...</span>
            </div>
            <input
              @input="debounce_search"
              :value="search"
              type="text"
              class="form-control"
              placeholder="Code, Description or BCID" />
            <div class="input-group-append">
              <button
                @click="clear_search"
                class="btn btn-outline-secondary"
                type="button">
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="btn-toolbar">
            <button
              @click="verbose = !verbose"
              type="button"
              class="btn btn-sm btn-outline-secondary mr-2">
              {{ verbose ? "Less Detail" : "More Detail" }}
            </button>

            <button
              @click="download_csv('filtered')"
              class="btn btn-sm btn-outline-secondary mr-2">
              Download
            </button>
            <button
              @click="download_csv('all')"
              class="btn btn-sm btn-outline-secondary mr-2">
              <loader-icon
                v-if="downloading"
                size="1.5x"
                class="custom-class icon-spinner mr-2"></loader-icon>
              Download All
            </button>

            <button
              @click="download_wms_xml('filtered')"
              class="btn btn-sm btn-outline-secondary mr-2">
              Download WMS
            </button>
            <button
              @click="download_wms_xml('all')"
              class="btn btn-sm btn-outline-secondary mr-2">
              <loader-icon
                v-if="downloading"
                size="1.5x"
                class="custom-class icon-spinner mr-2"></loader-icon>
              Download WMS All
            </button>

            <div
              class="btn-group"
              role="group">
              <button
                id="btnGroupDrop1"
                type="button"
                class="btn btn-secondary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                Dropdown
              </button>
              <div
                class="dropdown-menu p-2 m-2"
                aria-labelledby="btnGroupDrop1">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1" />
                  <label
                    class="form-check-label"
                    for="defaultCheck1">
                    Default checkbox
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1" />
                  <label
                    class="form-check-label"
                    for="defaultCheck1">
                    Default checkbox
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div class="row mb-2 mt-2">
        <div class="col text-right">
          <b>Showing: {{ this.filtered.length }}</b> of
          <b>{{ (this.algolia_hits && this.algolia_hits.nbHits) || 0 }}</b>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="tableFixHead">
            <table class="table table-bordered table-hover table-sm">
              <thead class="thead-dark">
                <tr>
                  <th></th>
                  <th @click="sort_by('code')">Code</th>
                  <th @click="sort_by('description')">Name</th>
                  <th @click="sort_by('barcode')">Barcode</th>
                  <th @click="sort_by('eta')">ETA</th>
                  <th
                    @click="sort_by('category1')"
                    class="w-10">
                    Cat 1
                  </th>
                  <th
                    @click="sort_by('category2')"
                    class="w-10">
                    Cat 2
                  </th>
                  <th
                    @click="sort_by('category3')"
                    class="w-10">
                    Cat 3
                  </th>
                  <th
                    @click="sort_by('custom3')"
                    class="w-5">
                    Online
                  </th>
                  <th
                    @click="sort_by('custom4')"
                    class="w-5">
                    BCID
                  </th>
                  <th
                    v-if="verbose"
                    class="w-5">
                    MOQ
                  </th>
                  <th
                    v-if="verbose"
                    class="w-5">
                    Mfr. Code
                  </th>
                  <th
                    v-if="verbose"
                    class="w-5">
                    A
                  </th>
                  <th
                    v-if="verbose"
                    class="w-5">
                    B
                  </th>
                  <th
                    v-if="verbose"
                    class="w-5">
                    C
                  </th>
                  <th
                    v-if="verbose"
                    class="w-5">
                    D
                  </th>
                  <th
                    v-if="verbose"
                    class="w-5">
                    E
                  </th>
                  <th
                    v-if="verbose"
                    class="w-5">
                    F
                  </th>
                  <th
                    v-if="verbose"
                    class="w-5">
                    SOH
                  </th>
                  <th
                    v-if="verbose"
                    class="w-5">
                    SOO
                  </th>
                  <th
                    v-if="verbose"
                    class="w-5">
                    Due Date
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="product in filtered"
                  :key="product.id"
                  :class="rowClass(product)">
                  <td class="text-center">
                    <router-link
                      :to="{
                        name: 'ProductEdit',
                        params: { product_id: product.objectID },
                      }">
                      <edit-icon
                        size="1x"
                        class="custom-class"></edit-icon>
                    </router-link>
                  </td>
                  <td>
                    <span v-html="product._highlightResult.code.value"></span>
                  </td>
                  <td>
                    <span v-html="product._highlightResult.description.value"></span>
                  </td>
                  <td>
                    <span v-html="product._highlightResult.barcode.value"></span>
                  </td>
                  <td @dblclick="filter_by(product.eta)">
                    {{ product.eta }}
                  </td>
                  <td @dblclick="filter_by(product.category1)">
                    <span v-html="product._highlightResult.category1.value"></span>
                  </td>
                  <td @dblclick="filter_by(product.category2)">
                    {{ product.category2 }}
                  </td>
                  <td @dblclick="filter_by(product.category3)">
                    {{ product.category3 }}
                  </td>
                  <td @dblclick="filter_by(product.custom3)">
                    {{ product.custom3 }}
                  </td>
                  <td>
                    <a
                      :href="`https://freshoffice.co.nz/manage/products/${product.custom4}/edit`"
                      target="_blank"
                      >{{ product.custom4 }}</a
                    >
                  </td>
                  <td v-if="verbose">{{ product.custom5 }}</td>
                  <td
                    v-if="verbose"
                    class="text-right">
                    {{ product.suppliers_code | currency }}
                  </td>
                  <td
                    v-if="verbose"
                    class="text-right">
                    {{ product.sellprice_a | currency }}
                  </td>
                  <td
                    v-if="verbose"
                    class="text-right">
                    {{ product.sellprice_b | currency }}
                  </td>
                  <td
                    v-if="verbose"
                    class="text-right">
                    {{ product.sellprice_c | currency }}
                  </td>
                  <td
                    v-if="verbose"
                    class="text-right">
                    {{ product.sellprice_d | currency }}
                  </td>
                  <td
                    v-if="verbose"
                    class="text-right">
                    {{ product.sellprice_e | currency }}
                  </td>
                  <td
                    v-if="verbose"
                    class="text-right">
                    {{ product.sellprice_f | currency }}
                  </td>
                  <td
                    v-if="verbose"
                    class="text-right">
                    {{ product.stockonhand }}
                  </td>
                  <td
                    v-if="verbose"
                    class="text-right">
                    {{ product.stock_on_order }}
                  </td>
                  <td
                    v-if="verbose"
                    class="text-right">
                    {{ product.stock_due_date | date_standard }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <Pagination
            v-if="pages"
            :pages="pages"
            v-on:updatePage="updatePage" />
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db, fb } from "@/firebaseConfig";
import algoliasearch from "algoliasearch/lite";
import moment from "moment";
import { orderBy } from "lodash";
import Papa from "papaparse";
import basicLayout from "@/components/basicLayout";
import Pagination from "@/components/Pagination";
import filter_mixins from "@/mixins/filter_mixins";
// import sort_mixins from "@/mixins/sort_mixins";
import { EditIcon, LoaderIcon } from "vue-feather-icons";

// const products = db.collection("products");

export default {
  name: "products",
  data() {
    return {
      products: [],
      // names: [],
      rules: [],
      with_rules: false,
      name: "",
      search: "",
      sort_toggle: 1,
      selected: [],
      selectAll: false,
      savedIndex: null,
      verbose: false,
      downloading: false,
      selected_page: 1,
      debounce: 100,
      sort_column: "",
      sort_order: "asc",
      searchClient: algoliasearch("Q4IJ7G4460", "d7bf0ceadda22d96b2e814d98b6ce15c"),
      algolia_index: {},
      algolia_hits: {},
    };
  },
  components: { basicLayout, Pagination, EditIcon, LoaderIcon },
  mixins: [filter_mixins],

  // firestore: {
  //   products: db.collection("products").where("hide", "==", false)
  // },

  created: async function () {
    this.algolia_index = this.searchClient.initIndex("products");
    this.algolia_hits = await this.algolia_index.search("", {
      filters: `hide:false`,
    });
  },
  methods: {
    sort_by(sort_column) {
      this.sort_column = sort_column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    debounce_search(event) {
      this.message = null;
      console.log("You are typing");
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        console.log("You stopped typing");
        this.search = event.target.value;
      }, 200);
    },
    updatePage(value) {
      this.selected_page = value;
    },
    // toggle_with_rules() {
    //   this.search = "";
    //   this.with_rules = !this.with_rules;
    // },
    rowClass(product) {
      return { "alert-primary": this.selected.includes(product.id) };
    },
    filter_by(filter) {
      this.search = filter;
    },
    clear_search() {
      this.search = "";
      this.selected_page = 1;
    },
    // select_page(index) {
    //   this.selected_page = index;
    //   this.clear_search();
    // },
    // previous_page() {
    //   if (this.selected_page > 1) {
    //     this.selected_page -= 1;
    //   }
    // },
    // next_page() {
    //   if (this.selected_page < this.pages) {
    //     this.selected_page += 1;
    //   }
    // },
    // paginationClass(index) {
    //   return index === this.selected_page ? "page-link-active" : "page-link";
    // },
    drag(event) {
      this.upload(event.dataTransfer.files[0]);
      event.target.value = "";
    },
    file_picker(event) {
      console.log("file picker");
      this.upload(event.target.files[0]);
      event.target.value = "";
    },
    upload: async function (file) {
      console.log("upload");
      console.log(file.name);
      const toast = this.$toast;
      const uploadProducts = fb.httpsCallable("uploadProducts");
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        /* eslint-disable no-unused-vars */
        complete(results) {
          console.log(JSON.stringify(results.data));
          uploadProducts(results.data).then((result) => {
            console.log(result.data);
            if (result.data.success) {
              toast.success("Products Uploaded", {
                position: "top-right",
                timeout: 3000,
              });
            } else {
              toast.error("Products Upload Error: " + result.data.error, {
                position: "top-right",
                timeout: 3000,
              });
            }
          });
        },
      });
    },

    download_wms_xml: async function (selection) {
      let download_products;

      if (selection === "all") {
        console.log("download all");
        this.downloading = true;
        const products_snapshot = await db.collection("products").get();
        download_products = products_snapshot.docs.map((doc) => doc.data());
      } else {
        download_products = this.filtered;
      }

      let xml_export = download_products.reduce((result, line) => {
        let product_xml = ``;
        product_xml += `<Product xmlns="InputProductMaster">`;

        product_xml += `<ProductDetails>`;
        product_xml += `<OwnerCode>FO</OwnerCode>`;
        product_xml += `<ProductCode>${this.escape_xml(line.code)}</ProductCode>`;
        product_xml += `<ProductDescription>${this.escape_xml(line.description).substring(
          0,
          100
        )}</ProductDescription>`;
        product_xml += `<ProductGroupRef>${line.custom7}</ProductGroupRef>`;
        product_xml += `<ProductCategoryRef />`;
        product_xml += `<ProductSubGroupRef>${line.category2}</ProductSubGroupRef>`;
        product_xml += `<ProductDepartmentRef />`;
        product_xml += `<ObsoleteFlag>F</ObsoleteFlag>`;
        product_xml += `<AutoRelease />`;
        product_xml += `<DefaultReceivedStatus />`;
        product_xml += `<ReleaseStatus />`;
        product_xml += `<InsertExpiryDate />`;
        product_xml += `<SetExpiryBy />`;
        product_xml += `<ShelfLifePeriodValue />`;
        product_xml += `<ShelfLifePeriodUnits />`;

        product_xml += `<SKUDetails>`;
        product_xml += `<UnitRef>${line.sell_unit}</UnitRef>`;
        product_xml += `<UnitQty>1</UnitQty>`;
        product_xml += `<BaseUOM>T</BaseUOM>`;
        product_xml += `<BarCode>${line.barcode}</BarCode>`;
        product_xml += `<SKUDescription1>${this.escape_xml(line.description).substring(0, 100)}</SKUDescription1>`;
        product_xml += `<SKUDescription2>${this.escape_xml(line.code)}</SKUDescription2>`;
        product_xml += `<Height />`;
        product_xml += `<Depth />`;
        product_xml += `<Width />`;
        product_xml += `<NetWeight />`;
        product_xml += `<GrossWeight />`;
        product_xml += `<UnitsPerLayer />`;
        product_xml += `<LayersPerPallet />`;
        product_xml += `<StoreStdUnits />`;
        product_xml += `<SKUObsoleteFlag>F</SKUObsoleteFlag>`;
        product_xml += `</SKUDetails>`;
        product_xml += `</ProductDetails>`;
        product_xml += `</Product>`;

        if (line.pack_qty && line.pack_barcode) {
          product_xml += `<Product xmlns="InputProductMaster">`;
          product_xml += `<ProductDetails>`;
          product_xml += `<OwnerCode>FO</OwnerCode>`;
          product_xml += `<ProductCode>${this.escape_xml(line.code)}</ProductCode>`;
          product_xml += `<ProductDescription>${this.escape_xml(line.description).substring(
            0,
            100
          )}</ProductDescription>`;
          product_xml += `<ProductGroupRef>${line.custom7}</ProductGroupRef>`;
          product_xml += `<ProductCategoryRef />`;
          product_xml += `<ProductSubGroupRef>${line.category2}</ProductSubGroupRef>`;
          product_xml += `<ProductDepartmentRef />`;
          product_xml += `<ObsoleteFlag>F</ObsoleteFlag>`;
          product_xml += `<AutoRelease />`;
          product_xml += `<DefaultReceivedStatus />`;
          product_xml += `<ReleaseStatus />`;
          product_xml += `<InsertExpiryDate />`;
          product_xml += `<SetExpiryBy />`;
          product_xml += `<ShelfLifePeriodValue />`;
          product_xml += `<ShelfLifePeriodUnits />`;

          product_xml += `<SKUDetails>`;
          product_xml += `<UnitRef>PACK</UnitRef>`;
          product_xml += `<UnitQty>${line.pack_qty}</UnitQty>`;
          product_xml += `<BaseUOM>F</BaseUOM>`;
          product_xml += `<BarCode>${line.pack_barcode}</BarCode>`;
          product_xml += `<SKUDescription1>${this.escape_xml(line.description).substring(0, 100)}</SKUDescription1>`;
          product_xml += `<SKUDescription2>${this.escape_xml(line.code)}</SKUDescription2>`;
          product_xml += `<Height />`;
          product_xml += `<Depth />`;
          product_xml += `<Width />`;
          product_xml += `<NetWeight />`;
          product_xml += `<GrossWeight />`;
          product_xml += `<UnitsPerLayer />`;
          product_xml += `<LayersPerPallet />`;
          product_xml += `<StoreStdUnits />`;
          product_xml += `<SKUObsoleteFlag>F</SKUObsoleteFlag>`;
          product_xml += `</SKUDetails>`;
          product_xml += `</ProductDetails>`;
          product_xml += `</Product>`;
        }

        if (line.inner_qty && line.inner_barcode) {
          product_xml += `<Product xmlns="InputProductMaster">`;
          product_xml += `<ProductDetails>`;
          product_xml += `<OwnerCode>FO</OwnerCode>`;
          product_xml += `<ProductCode>${this.escape_xml(line.code)}</ProductCode>`;
          product_xml += `<ProductDescription>${this.escape_xml(line.description).substring(
            0,
            100
          )}</ProductDescription>`;
          product_xml += `<ProductGroupRef>${line.custom7}</ProductGroupRef>`;
          product_xml += `<ProductCategoryRef />`;
          product_xml += `<ProductSubGroupRef>${line.category2}</ProductSubGroupRef>`;
          product_xml += `<ProductDepartmentRef />`;
          product_xml += `<ObsoleteFlag>F</ObsoleteFlag>`;
          product_xml += `<AutoRelease />`;
          product_xml += `<DefaultReceivedStatus />`;
          product_xml += `<ReleaseStatus />`;
          product_xml += `<InsertExpiryDate />`;
          product_xml += `<SetExpiryBy />`;
          product_xml += `<ShelfLifePeriodValue />`;
          product_xml += `<ShelfLifePeriodUnits />`;

          product_xml += `<SKUDetails>`;
          product_xml += `<UnitRef>INNER</UnitRef>`;
          product_xml += `<UnitQty>${line.inner_qty}</UnitQty>`;
          product_xml += `<BaseUOM>F</BaseUOM>`;
          product_xml += `<BarCode>${line.inner_barcode}</BarCode>`;
          product_xml += `<SKUDescription1>${this.escape_xml(line.description).substring(0, 100)}</SKUDescription1>`;
          product_xml += `<SKUDescription2>${this.escape_xml(line.code)}</SKUDescription2>`;
          product_xml += `<Height />`;
          product_xml += `<Depth />`;
          product_xml += `<Width />`;
          product_xml += `<NetWeight />`;
          product_xml += `<GrossWeight />`;
          product_xml += `<UnitsPerLayer />`;
          product_xml += `<LayersPerPallet />`;
          product_xml += `<StoreStdUnits />`;
          product_xml += `<SKUObsoleteFlag>F</SKUObsoleteFlag>`;
          product_xml += `</SKUDetails>`;
          product_xml += `</ProductDetails>`;
          product_xml += `</Product>`;
        }

        if (line.carton_qty && line.carton_barcode) {
          product_xml += `<Product xmlns="InputProductMaster">`;
          product_xml += `<ProductDetails>`;
          product_xml += `<OwnerCode>FO</OwnerCode>`;
          product_xml += `<ProductCode>${this.escape_xml(line.code)}</ProductCode>`;
          product_xml += `<ProductDescription>${this.escape_xml(line.description).substring(
            0,
            100
          )}</ProductDescription>`;
          product_xml += `<ProductGroupRef>${line.custom7}</ProductGroupRef>`;
          product_xml += `<ProductCategoryRef />`;
          product_xml += `<ProductSubGroupRef>${line.category2}</ProductSubGroupRef>`;
          product_xml += `<ProductDepartmentRef />`;
          product_xml += `<ObsoleteFlag>F</ObsoleteFlag>`;
          product_xml += `<AutoRelease />`;
          product_xml += `<DefaultReceivedStatus />`;
          product_xml += `<ReleaseStatus />`;
          product_xml += `<InsertExpiryDate />`;
          product_xml += `<SetExpiryBy />`;
          product_xml += `<ShelfLifePeriodValue />`;
          product_xml += `<ShelfLifePeriodUnits />`;

          product_xml += `<SKUDetails>`;
          product_xml += `<UnitRef>CARTON</UnitRef>`;
          product_xml += `<UnitQty>${line.carton_qty}</UnitQty>`;
          product_xml += `<BaseUOM>F</BaseUOM>`;
          product_xml += `<BarCode>${line.carton_barcode}</BarCode>`;
          product_xml += `<SKUDescription1>${this.escape_xml(line.description).substring(0, 100)}</SKUDescription1>`;
          product_xml += `<SKUDescription2>${this.escape_xml(line.code)}></SKUDescription2>`;
          product_xml += `<Height />`;
          product_xml += `<Depth />`;
          product_xml += `<Width />`;
          product_xml += `<NetWeight />`;
          product_xml += `<GrossWeight />`;
          product_xml += `<UnitsPerLayer />`;
          product_xml += `<LayersPerPallet />`;
          product_xml += `<StoreStdUnits />`;
          product_xml += `<SKUObsoleteFlag>F</SKUObsoleteFlag>`;
          product_xml += `</SKUDetails>`;
          product_xml += `</ProductDetails>`;
          product_xml += `</Product>`;
        }

        result += product_xml;
        return result;
      }, "");

      let xml_file = ``;
      xml_file += `<?xml version="1.0" encoding="utf-8" ?>`;
      xml_file += `<ProductMaster>`;
      xml_file += xml_export;
      xml_file += `</ProductMaster>`;

      // xml_file = this.escape_xml(xml_file);

      const xml_name = `products-wms-${moment().format("YYYY-MM-DD")}.xml`;
      console.log(xml_name);
      this.downloading = false;
      const anchor = document.createElement("a");
      anchor.href = "data:text/xml," + encodeURIComponent(xml_file);
      anchor.download = xml_name;
      anchor.click();
    },

    download_wms_csv: async function (selection) {
      let download_products;

      if (selection === "all") {
        console.log("download all");
        this.downloading = true;
        const products_snapshot = await db.collection("products").get();
        download_products = products_snapshot.docs.map((doc) => doc.data());
      } else {
        download_products = this.filtered;
      }

      let csv_export = download_products.reduce((result, line) => {
        let wms_sku = {
          OwnerCode: "FO",
          ProductCode: line.code,
          ProductGroupRef: line.custom7,
          Description1: line.description,
          Description2: "",
          UnitRef: line.sell_unit,
          ProductSubGroupRef: line.category2,
          ProductCategoryRef: "",
          ProductDepartmentRef: "",
          ObsoleteFlag: "",
          UnitQty: 1,
          StorageTemp: "",
          BarCode: line.barcode,
          SKUDescription: line.description,
          Height: "",
          Depth: "",
          Width: "",
          NetWeight: "",
          GrossWeight: "",
          UnitsPerLayer: "",
          LayersPerPallet: "",
          BaseUOM: "T",
          StoreStdUnits: "",
          SkuObsoleteFlag: "",
          AutoRelease: "",
          DefaultReceivedStatus: "",
          ReleaseStatus: "",
          InsertExpiryDate: "",
          SetExpiryBy: "",
          ShelfLifePeriodUnit: "",
          ShelfLifePeriodValue: "",
        };

        result.push(wms_sku);

        if (line.pack_qty && line.pack_barcode) {
          let wms_pack = {
            OwnerCode: "FO",
            ProductCode: line.code,
            ProductGroupRef: line.custom7,
            Description1: line.description,
            UnitRef: "PACK",
            ProductSubGroupRef: line.category2,
            UnitQty: line.pack_qty,
            BarCode: line.pack_barcode,
            SKUDescription: line.description,
            BaseUOM: "F",
          };
          result.push(wms_pack);
        }

        if (line.inner_qty && line.inner_barcode) {
          let wms_inner = {
            OwnerCode: "FO",
            ProductCode: line.code,
            ProductGroupRef: line.custom7,
            Description1: line.description,
            UnitRef: "INNER",
            ProductSubGroupRef: line.category2,
            UnitQty: line.inner_qty,
            BarCode: line.inner_barcode,
            SKUDescription: line.description,
            BaseUOM: "F",
          };
          result.push(wms_inner);
        }

        if (line.carton_qty && line.carton_barcode) {
          let wms_carton = {
            OwnerCode: "FO",
            ProductCode: line.code,
            ProductGroupRef: line.custom7,
            Description1: line.description,
            UnitRef: "CARTON",
            ProductSubGroupRef: line.category2,
            UnitQty: line.carton_qty,
            BarCode: line.carton_barcode,
            SKUDescription: line.description,
            BaseUOM: "F",
          };
          result.push(wms_carton);
        }

        return result;
      }, []);
      const csv = Papa.unparse({
        fields: [
          "OwnerCode",
          "ProductCode",
          "ProductGroupRef",
          "Description1",
          "Description2",
          "UnitRef",
          "ProductSubGroupRef",
          "ProductCategoryRef",
          "ProductDepartmentRef",
          "ObsoleteFlag",
          "UnitQty",
          "StorageTemp",
          "BarCode",
          "SKUDescription",
          "Height",
          "Depth",
          "Width",
          "NetWeight",
          "GrossWeight",
          "UnitsPerLayer",
          "LayersPerPallet",
          "BaseUOM",
          "StoreStdUnits",
          "SkuObsoleteFlag",
          "AutoRelease",
          "DefaultReceivedStatus",
          "ReleaseStatus",
          "InsertExpiryDate",
          "SetExpiryBy",
          "ShelfLifePeriodUnit",
          "ShelfLifePeriodValue",
        ],

        data: csv_export,
        quotes: true,
      });

      const csv_name = `products-wms-${moment().format("YYYY-MM-DD")}.csv`;
      console.log(csv_name);
      this.downloading = false;
      const anchor = document.createElement("a");
      anchor.href = "data:text/csv," + encodeURIComponent(csv);
      anchor.download = csv_name;
      anchor.click();
    },

    download_csv: async function (selection) {
      let download_products;

      if (selection === "all") {
        console.log("download all");
        this.downloading = true;
        const products_snapshot = await db.collection("products").get();
        download_products = products_snapshot.docs.map((doc) => doc.data());
      } else {
        download_products = this.filtered;
      }

      let csv_export = download_products.map((line) => {
        line.barcode = (line.barcode && `'${line.barcode}`) || "";
        line.pack_barcode = (line.pack_barcode && `'${line.pack_barcode}`) || "";
        line.inner_barcode = (line.inner_barcode && `'${line.inner_barcode}`) || "";
        line.carton_barcode = (line.carton_barcode && `'${line.carton_barcode}`) || "";
        line.suppliers_code = (line.suppliers_code && `'${line.suppliers_code}`) || "";

        if (line.stockonhand < 1) {
          line.eta = line.eta || line.stock_due_date || "";
        }

        if (line.eta) {
          line.stockonhand = 0;
        }

        return line;
      });
      const csv = Papa.unparse({
        fields: [
          "code",
          "barcode",
          "description",
          "long_description",
          "eta",
          "category1",
          "category2",
          "category3",
          "category4",
          "supplier",
          "suppliers_code",
          "custom1",
          "custom2",
          "custom3",
          "custom4",
          "custom5",
          "custom6",
          "custom7",
          "custom8",
          "sell_unit",
          "sellprice_a",
          "sellprice_b",
          "sellprice_c",
          "sellprice_d",
          "sellprice_e",
          "sellprice_f",
          "stockonhand",
          "colour",
          "pick_location",
          "bulk_location_1",
          "bulk_location_2",
          "unit_length",
          "unit_width",
          "unit_height",
          "unit_weight",
          "pack_barcode",
          "pack_qty",
          "pack_length",
          "pack_width",
          "pack_height",
          "pack_weight",
          "inner_barcode",
          "inner_qty",
          "inner_length",
          "inner_width",
          "inner_height",
          "inner_weight",
          "carton_barcode",
          "carton_qty",
          "carton_length",
          "carton_width",
          "carton_height",
          "carton_weight",
          "dangerous_goods",
          "rrp_inc",
          "country_origin",
          "bullet_1",
          "bullet_2",
          "bullet_3",
          "bullet_4",
          "bullet_5",
          "bullet_6",
        ],
        data: csv_export,
        quotes: true,
      });

      const csv_name = `products-${moment().format("YYYY-MM-DD")}.csv`;
      console.log(csv_name);
      this.downloading = false;
      const anchor = document.createElement("a");
      anchor.href = "data:text/csv," + encodeURIComponent(csv);
      anchor.download = csv_name;
      anchor.click();
    },

    escape_xml: function (xml) {
      return xml
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
    },
  },
  computed: {
    filtered() {
      let visible_products = this.algolia_hits.hits || []; //this.products.slice();

      if (this.sort_column) {
        visible_products = orderBy(visible_products, this.sort_column, this.sort_order);
      }

      return visible_products;
    },

    pages() {
      return this.algolia_hits.nbPages;
      // return Math.ceil(this.products.length / 200);
    },
    search_query() {
      return { search: this.search, page: this.selected_page - 1 };
    },
  },
  watch: {
    search_query: {
      // immediate: true,
      handler: async function (query) {
        console.log(query);
        this.algolia_hits = await this.algolia_index.search(query.search, {
          page: query.page,
          filters: `hide:false`,
        });
      },
    },

    // search: function(newSearch) {
    //   if (newSearch) {
    //     console.log(newSearch);
    //     this.selected = [];
    //     this.selectAll = false;
    //     this.selected_page = -1;
    //   } else {
    //     this.selected_page = 1;
    //   }
    // }
  },
};
</script>

<style scoped>
.container {
  width: 100% !important;
}

@keyframes anim-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.icon-spinner {
  display: inline-block;
  animation: anim-rotate 2s infinite linear;
}
.icon-spinner--steps {
  animation: anim-rotate 1s infinite steps(8);
}
.icon-spinner--steps2 {
  animation: anim-rotate 1s infinite steps(12);
}
</style>
<style >
td em {
  font-style: normal;
  font-weight: bold;
  background-color: #cedcec;
}
</style>
