<template>
  <basicLayout>
    <div class="p-2">
      <h5>EMAIL LISTS</h5>
      <div class="row">
        <div class="col-xl-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Search...</span>
            </div>
            <input
              v-model="search"
              type="text"
              class="form-control"
              placeholder="PO, SO, Name, or Carrier" />
            <div class="input-group-append">
              <button
                @click="clear_search"
                class="btn btn-outline-secondary"
                type="button">
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="btn-toolbar">
            <router-link
              class="btn btn-sm btn-outline-secondary mr-2"
              :to="{ name: 'EmailListCreate' }"
              >New List</router-link
            >
            <router-link
              class="btn btn-sm btn-outline-secondary mr-2"
              :to="{ name: 'FeedFields' }"
              >Edit Fields</router-link
            >
          </div>
          <div class="row mb-2">
            <div class="col text-right">
              <b>Showing: {{ this.filtered.length }}</b> of
              <b>{{ this.email_lists.length }}</b>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="tableFixHead">
            <table class="table table-bordered table-hover table-sm">
              <thead class="thead-dark">
                <tr>
                  <th>Name</th>
                  <th>Days</th>
                  <th>Time</th>
                  <th>Members</th>
                  <th>Last Sent</th>
                  <th>Enabled</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="email_list in filtered"
                  :key="email_list.id">
                  <td>{{ email_list.list_name }}</td>
                  <td>{{ email_list.days.join(", ") }}</td>
                  <td>{{ email_list.time }}</td>
                  <td>{{ email_list.recipients.length }}</td>
                  <td>{{ email_list.last_sent | moment_long }}</td>
                  <td>{{ email_list.enabled }}</td>
                  <td class="text-center">
                    <router-link
                      class="btn btn-x-sm btn-outline-secondary"
                      :to="{
                        name: 'EmailListEdit',
                        params: { email_list_id: email_list.id },
                      }">
                      <edit-icon
                        size="1x"
                        class="custom-class"></edit-icon
                      >&nbsp;Edit
                    </router-link>
                  </td>

                  <td class="text-center">
                    <button
                      @click="send_now(email_list)"
                      type="button"
                      class="btn btn-x-sm btn-outline-success"
                      title="Send Now">
                      <send-icon
                        size="1x"
                        class="custom-class"></send-icon>
                      &nbsp;{{ confirm_send ? "Really Send?" : "Send" }}
                    </button>
                  </td>
                  <!-- <td @click="deleteItem(rule)" class="text-center" href="#">
                <font-awesome-icon :icon="['fas', 'trash']" />
              </td>-->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import moment from "moment";
import { db, fb } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import { EditIcon, SendIcon } from "vue-feather-icons";
const email_lists = db.collection("email_lists");

export default {
  name: "email_lists",
  data() {
    return {
      email_lists: [],
      search: "",
      sort_toggle: 1,
      confirm_send: false,
    };
  },
  components: { basicLayout, EditIcon, SendIcon },

  created() {
    return this.$bind("email_lists", email_lists, { maxRefDepth: 1 });
  },

  methods: {
    send_now(email_list) {
      if (this.confirm_send) {
        let copy_list = { ...email_list };
        copy_list.id = email_list.id;
        copy_list.test = false;
        console.log(copy_list);
        const process_email_record = fb.httpsCallable("process_email_record");
        process_email_record(copy_list).then((result) => {
          console.log(JSON.stringify(result));
        });
        this.confirm_send = false;
        return;
      }
      this.confirm_send = true;
      setTimeout(() => {
        this.confirm_send = false;
      }, 4000);
    },
    filter_by(filter) {
      this.search = filter;
    },
    deleteItem(email_list) {
      db.collection("email_lists").doc(email_list.id).delete();
    },

    clear_search() {
      this.search = "";
    },
  },

  computed: {
    filtered() {
      return this.email_lists.filter((email_list) => {
        const s = Object.values(email_list);
        const su = s.toString().toUpperCase();
        return su.match(this.search.toString().toUpperCase());
      });
    },
  },
  filters: {
    moment_short: function (date) {
      return (date && moment.utc(date.toDate()).local().format("ll")) || "";
    },
    moment_long: function (date) {
      return (date && moment.utc(date.toDate()).local().format("LLLL")) || "";
    },
  },
};
</script>

<style scoped>
</style>