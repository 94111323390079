<template>
  <basicLayout>
    <div class="row">
      <div class="col">
        <h4>Portal Road Map</h4>
      </div>
      <div class="col text-right">
        <button @click="addList" type="button" class="btn btn-success">
          + Add List
        </button>
      </div>
    </div>
    <div>
      <draggable
        v-model="lists"
        class="row flex-nowrap mt-1"
        v-bind="getDragOptions"
        @change="saveLists"
      >
        <TaskList
          v-for="(listItem, index) in lists"
          :key="index"
          :list="listItem"
        ></TaskList>
      </draggable>
    </div>
  </basicLayout>
</template>
<script>
import { db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import TaskList from "@/components/Kanban/TaskList";
import Draggable from "vuedraggable";
// import { mapGetters, mapActions } from "vuex"
export default {
  name: "Board",
  components: {
    basicLayout,
    TaskList,
    Draggable
  },
  data() {
    return {
      board: {
        id: "1",
        name: "Project Tracker",
        description: "A board to track a project"
      },
      lists: []
    };
  },
  firestore() {
    return {
      lists: db.collection("tasklists").orderBy("index")
    };
  },

  computed: {
    getDragOptions() {
      return {
        animation: "200",
        ghostClass: "ghost",
        handle: ".card",
        group: "kanban-board-lists"
      };
    }
  },
  methods: {
    addList() {
      console.log("adding list");
      const index = this.lists.length;
      db.collection("tasklists").add({
        name: "New List",
        items: [],
        index: index
      });
    },
    saveLists() {
      console.log("saving lists");
      this.lists.map(async (list, index) => {
        console.log(JSON.stringify(list));
        await db
          .collection("tasklists")
          .doc(list.id)
          .set({ index: index }, { merge: true });
      });
    }
  }
};
</script>
