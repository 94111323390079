<template>
  <basicLayout>
    <div class="p-2">
      <h5>TRACKING</h5>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Search...</span>
            </div>
            <input
              v-model="search"
              type="text"
              class="form-control"
              placeholder="PO, SO, Name, or Carrier"
            />
            <div class="input-group-append">
              <button @click="clear_search" class="btn btn-outline-secondary" type="button">Clear</button>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2">
          <button @click="refresh" type="button" class="btn btn-success btn-block">Refresh</button>
        </div>
      </div>
      <div v-if="shipped_this_month" class="row mb-2">
        <div class="col">
          <div class="card alert-primary">
            <div class="card-body card-sm">
              <h6>SHIPPED THIS MONTH</h6>
              <h3>{{shipped_this_month | number}}</h3>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card alert-success">
            <div class="card-body card-sm">
              <h6>DELIVERED THIS MONTH</h6>
              <h3>{{delivered_this_month | number}} ({{delivered_this_month / shipped_this_month * 100 | number}}%)</h3>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card alert-primary">
            <div class="card-body card-sm">
              <h6>SHIPPED LAST 7 DAYS</h6>
              <h3>{{shipped_7_days | number}}</h3>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card alert-success">
            <div class="card-body card-sm">
              <h6>DELIVERED LAST 7 DAYS</h6>
              <h3>{{delivered_7_days | number}} ({{delivered_7_days / shipped_7_days * 100 | number}}%)</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="col text-right">
        <b>Showing: {{this.filtered.length}}</b> of
        <b>{{this.orders.length}}</b>
      </div>
    </div>

    <div class="tableFixHead">
      <table class="table table-bordered table-hover table-sm">
        <thead class="thead-dark">
          <tr>
            <th @click="sort_date(orders,'shipped_date')" class="text-left w-20">Date Shipped</th>
            <th @click="sort_string(orders,'order_number')" class="text-left w-10">SO No.</th>
            <th
              @click="sort_string(orders,'reference')"
              class="text-left d-none d-sm-table-cell w-10"
            >PO No.</th>
            <th @click="sort_string(orders,'name')" class="text-left d-none d-sm-table-cell w-20">To</th>
            <th
              @click="sort_string(orders,'carrier')"
              class="text-left d-none d-sm-table-cell w-10"
            >Carrier</th>
            <th class="text-left d-none d-sm-table-cell w-20">Tracking Number</th>
            <th class="text-left w-10">Status</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(order, index) in filtered">
            <tr v-bind:class="{ 'text-success': delivered(order)}" :key="order.order_id">
              <td
                class="text-left"
                :title="order.shipped_date | moment_long"
              >{{order.shipped_date | moment_short }}</td>
              <td class="text-left d-none d-sm-table-cell">{{order.order_number}}</td>
              <td class="text-left">{{order.reference}}</td>
              <td class="text-left d-none d-sm-table-cell">{{order.name}}</td>
              <td class="text-left d-none d-sm-table-cell">{{order.carrier}}</td>
              <td class="text-left d-none d-sm-table-cell">
                <ul v-if="order.tracking_number" class="list-unstyled p-0 m-0">
                  <li v-for="tn in (order.tracking_number.split(','))" :key="tn">
                    <span @click="starshipit(tn, index)" title="Click to track">
                      <compass-icon size="1.5x" class="custom-class"></compass-icon>&nbsp;
                      <small>{{tn}}</small>
                    </span>
                  </li>
                </ul>
              </td>

              <td
                v-if="order.tracking_short_status"
                class="text-left d-none d-sm-table-cell"
              >{{order.tracking_short_status}}</td>
              <td v-else class="text-left d-none d-sm-table-cell">Printed</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </basicLayout>
</template>

<script>
import moment from "moment";

import { CompassIcon } from "vue-feather-icons";
import basicLayout from "@/components/basicLayout";
import sort_mixins from "@/mixins/sort_mixins";
import { starshipit_tracking } from "../mixins/starshipit_tracking";

export default {
  name: "tracking",
  data() {
    return {
      orders: [],
      search: "",
      sort_toggle: 1
    };
  },
  components: { basicLayout, CompassIcon },
  mixins: [sort_mixins],

  created() {
    this.refresh();
  },
  methods: {
    
    starshipit_tracking,
    filter_by(filter) {
      this.search = filter;
    },
    delivered(order) {
      return order.tracking_short_status == "Delivered";
    },
    clear_search() {
      this.search = "";
    },
    refresh() {      
      this.starshipit_tracking().then(result => {
        this.orders = result;
      });
    },
    starshipit(tn) {
      window.open(
        `https://app.starshipit.com/track.aspx?code=${tn.trim()}`,
        "_blank"
      );
    },
    shipped_since: function(date) {
      return this.filtered.filter(order => {
        return order.shipped_date >= date;
      }).length;
    },
    delivered_since: function(date) {
      return this.filtered.filter(order => {
        return (
          order.shipped_date >= date &&
          order.tracking_short_status === "Delivered"
        );
      }).length;
    }
  },
  computed: {
    filtered: function() {
      return this.orders.filter(order => {
        const s =
          order.order_number + order.reference + order.name + order.carrier;
        const su = s.toUpperCase();
        return su.match(this.search.toUpperCase());
      });
    },

    shipped_this_month: function() {
      const start_of_month = moment()
        .startOf("month")
        .format("YYYY-MM-DD hh:mm");
      return this.shipped_since(start_of_month);
    },
    delivered_this_month: function() {
      const start_of_month = moment()
        .startOf("month")
        .format("YYYY-MM-DD hh:mm");
      return this.delivered_since(start_of_month);
    },

    shipped_7_days: function() {
      const week_ago = moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD hh:mm");
      return this.shipped_since(week_ago);
    },
    delivered_7_days: function() {
      const week_ago = moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD hh:mm");
      return this.delivered_since(week_ago);
    }
  },

  filters: {
    moment_short: function(date) {
      return moment
        .utc(date)
        .local()
        .format("ll");
    },
    moment_long: function(date) {
      return moment
        .utc(date)
        .local()
        .format("LLLL");
    },
    blank_tracking: function(tracking_numbers) {
      return tracking_numbers || "-";
    },
    number: function(no) {
      return no.toLocaleString("en-NZ", { maximumFractionDigits: 0 });
    }
  }
};
</script>

<style scoped>
.btn-x-sm {
  padding: 0.2rem 0.4rem;
  font-size: 0.8rem;
  line-height: 1;
  border-radius: 0.2rem;
}

.card-body {
  padding: 0.8rem;
}
</style>
