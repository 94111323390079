<template>
  <basicLayout>
    <div class="container">
      <div class="card">
        <div class="card-body p-3">
          <h4>{{ user.name }}</h4>
          <form @submit.prevent="save_user">
            <div class="form-group">
              <label for="Name">Name</label>
              <input
                v-model="user.name"
                type="text"
                class="form-control"
                id="name"
                placeholder="Name"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Email address</label>
              <input
                v-model="user.email"
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                placeholder="Enter email"
              />
            </div>

            <button type="submit" class="btn btn-secondary">Save</button>
          </form>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";

export default {
  name: "userProfile",
  data() {
    return {
      user: {}
    };
  },
  firestore() {
    return {
      user: db.collection("users").doc(this.$route.params.id)
    };
  },
  components: {
    basicLayout
  },

  methods: {
    save_user() {
      console.log(this.user.id);
      db.collection("users")
        .doc(this.user.id)
        .set(this.user);
      this.$router.push("/users");
    }
  }
};
</script>

