<template>
  <div class="card">
    <div class="card-header">
      <h5 class="my-0 font-weight-normal">ORDERS</h5>
    </div>
    <div class="card-body">
      <div class="btn-toolbar mb-2">
        <router-link :to="{ name: 'OrdersOnline' }">
          <button class="btn btn-sm btn-outline-secondary mr-2">
            Go To Orders
          </button>
        </router-link>
      </div>
      <div class="tableFixHead">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th class="text-left" scope="col">Date</th>
              <th class="text-left" scope="col">ID</th>
              <th class="text-left" scope="col">Name</th>
              <th class="text-left" scope="col">Company</th>
              <th class="text-right" scope="col">Total</th>
              <th class="text-right" scope="col">Imported</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in orders" :key="order.id">
              <td class="text-left">
                {{ order.transaction_date | date_short }}
              </td>
              <td class="text-left">{{ order.id }}</td>
              <td class="text-left">
                {{ order.first_name }} {{ order.last_name }}
              </td>
              <td class="text-left">{{ order.company }}</td>

              <td class="text-right">
                {{ order.transaction_total | currency }}
              </td>

              <td
                v-if="!order.imported_to_mw && !order.moneyworks_response"
                class="text-center"
              >
                <router-link :to="{ name: 'Orders' }">
                  <span class="badge badge-pill badge-warning">MW</span>
                </router-link>
              </td>
              <td
                v-else-if="order.imported_to_mw && order.moneyworks_response"
                class="text-center"
              >
                <router-link :to="{ name: 'Orders' }">
                  <span class="badge badge-pill badge-success">MW</span>
                </router-link>
              </td>
              <td
                v-else-if="!order.imported_to_mw && order.moneyworks_response"
                class="text-center"
              >
                <router-link :to="{ name: 'Orders' }">
                  <span class="badge badge-pill badge-danger">MW</span>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {  db } from "@/firebaseConfig";
import filter_mixins from "@/mixins/filter_mixins";
export default {
  name: "dashboard",
  data() {
       return {
      orders: [],
    };
  },
        mixins: [filter_mixins],

  firestore: {
    orders: db
      .collection("bc_orders")
      .orderBy("transaction_date", "desc")
      .limit(200),
  },
};
</script>

<style scoped>
table {
  font-size: 0.75rem;
}
</style>

