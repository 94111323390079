<template>
  <basicLayout>
    <div class="p-2">
      <h5>PRODUCTS SEARCH</h5>

      <div class="row">
        <div class="col-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Search...</span>
            </div>
            <input
              @input="debounce_search"
              v-model="search"
              type="text"
              class="form-control"
              placeholder="Code, Description or BCID"
              autofocus
              ref="search"
            />
            <div class="input-group-append">
              <button
                @click="clear_search"
                class="btn btn-outline-secondary"
                type="button"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div class="row mb-2 mt-2">
        <div class="col text-right">
          <b>Showing: {{ this.filtered.length }}</b> of
          <b>{{ this.products.length }}</b>
        </div>
      </div>

      <div class="tableFixHead">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th></th>
              <th @click="sort_string(products, 'code')">Code</th>
              <th @click="sort_string(products, 'description')">Name</th>
              <th @click="sort_string(products, 'category1')">Brand</th>
              <th @click="sort_string(products, 'barcode')">Barcode</th>
              <th @click="sort_string(products, 'inner_barcode')">
                Inner Barcode
              </th>
              <th @click="sort_string(products, 'inner_qty')">Inner Qty</th>
              <th @click="sort_string(products, 'carton_barcode')">
                Outer Barcode
              </th>

              <th @click="sort_string(products, 'carton_qty')">Outer Qty</th>

              <th class="w-5">Mfr. Code</th>
              <th @click="sort_string(products, 'location')">Location</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in filtered" :key="product.id">
              <td class="text-center">
                <router-link
                  :to="{
                    name: 'ProductEdit',
                    params: { product_id: product.id },
                  }"
                >
                  <edit-icon size="1x" class="custom-class"></edit-icon>
                </router-link>
              </td>
              <td>{{ product.code }}</td>
              <td>{{ product.description }}</td>
              <td @dblclick="filter_by(product.category1)">
                {{ product.category1 }}
              </td>
              <td>{{ product.barcode }}</td>
              <td>{{ product.inner_barcode }}</td>
              <td>{{ product.inner_qty }}</td>
              <td>{{ product.carton_barcode }}</td>
              <td>{{ product.carton_qty }}</td>

              <td @dblclick="filter_by(product.suppliers_code)">
                {{ product.suppliers_code }}
              </td>
              <td @dblclick="filter_by(product.location)">
                {{ product.location }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        v-if="pages && !search"
        :pages="pages"
        v-on:updatePage="updatePage"
      />
    </div>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import Pagination from "@/components/Pagination";
import filter_mixins from "@/mixins/filter_mixins";
import sort_mixins from "@/mixins/sort_mixins";
import { EditIcon } from "vue-feather-icons";

export default {
  name: "products",
  data() {
    return {
      products: [],
      names: [],
      name: "",
      search: "",
      sort_toggle: 1,
      selected_page: 1,
      debounce: 100
    };
  },
  components: { basicLayout, Pagination, EditIcon },
  mixins: [filter_mixins, sort_mixins],

  firestore: {
    products: db.collection("products").where("hide", "==", false)
  },
  methods: {
    debounce_search(event) {
      this.message = null;
      console.log("You are typing");
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        console.log("You stopped typing");
        this.search = event.target.value;
      }, 400);
    },
    updatePage(value) {
      this.selected_page = value;
      this.clear_search();
    },
    toggle_with_rules() {
      this.search = "";
      this.with_rules = !this.with_rules;
    },

    filter_by(filter) {
      this.search = filter;
    },

    clear_search() {
      this.search = "";
      this.$refs.search.focus();
    },
    select_page(index) {
      this.selected_page = index;
      this.clear_search();
      this.$refs.search.focus();
    },
    previous_page() {
      if (this.selected_page > 1) {
        this.selected_page -= 1;
      }
      this.$refs.search.focus();
    },
    next_page() {
      if (this.selected_page < this.pages) {
        this.selected_page += 1;
      }
      this.$refs.search.focus();
    },
    paginationClass(index) {
      return index === this.selected_page ? "page-link-active" : "page-link";
    }
  },
  computed: {
    filtered() {
      console.log(this.products.length);
      if (!this.search) {
        let start = (this.selected_page - 1) * 200;
        let end = start + 200;
        return this.products.slice(start, end);
      }
      return this.products.filter(product => {
        const s = Object.values(product);
        const su = s.toString().toUpperCase();
        return su.match(this.search.toString().toUpperCase());
      });
    },
    pages() {
      return Math.ceil(this.products.length / 200);
    }
  },
  watch: {
    search: function(newSearch) {
      if (newSearch) {
        console.log(newSearch);

        this.selected_page = -1;
      } else {
        this.selected_page = 1;
      }
    }
  }
};
</script>

<style scoped>
.container {
  width: 100% !important;
}
</style>
