<template>
  <div>
    <Spinner v-if="isLoading" />
    <nav
      class="
        navbar navbar-expand-sm navbar-light
        bg-light
        border-bottom
        fixed-top
      "
    >
      <router-link to="/" class="navbar-brand text-center">
        <img src="../assets/logo.png" width="auto" height="40" alt />
      </router-link>

      <sidebar-icon
        @click="toggle_menu = !toggle_menu"
        size="1.5x"
        role="button"
        class="text-secondary"
      ></sidebar-icon>

      <!-- <button @click="toggle_menu=!toggle_menu" class="btn btn-primary" id="menu-toggle">Toggle Menu</button> -->

      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
          <!-- <li class="nav-item active">
            <a class="nav-link" href="#">
              Home
              <span class="sr-only">(current)</span>
            </a>
          </li>-->

          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              >{{ currentUser.email }}</a
            >
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="navbarDropdown"
            >
              <!-- <router-link
                :to="{ name: 'UserProfile', params: { id: userProfile.id }}"
                class="dropdown-item"
              >
                <user-icon size="1x" class="custom-class"></user-icon>&nbsp;Profile
              </router-link>-->
              <!-- <a class="dropdown-item" href="#">Another action</a> -->
              <a @click="logout" class="dropdown-item" href="#">
                <log-out-icon size="1x" class="custom-class"></log-out-icon
                >&nbsp;Logout
              </a>
              <!-- <a class="dropdown-item" href="#">Another action</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Something else here</a>-->
            </div>
          </li>
        </ul>
      </div>
    </nav>

    <div class="d-flex" :class="{ toggled: toggle_menu }" id="wrapper">
      <!-- Sidebar -->
      <div class="sidebar border-right" id="sidebar-wrapper">
        <div
          class="list-group list-group-flush sticky-top"
          style="overflow-y: auto; max-height: 90vh"
        >
          <router-link
            class="
              list-group-item list-group-item-action
              bg-transparent
              text-light
            "
            :to="{ name: 'Dashboard' }"
            title="Dashboard"
          >
            <grid-icon size="1.5x" class="custom-class"></grid-icon>
            <span class="d-none d-md-inline">&nbsp;Dashboard</span>
          </router-link>
          <router-link
            class="
              list-group-item list-group-item-action
              bg-transparent
              text-light
            "
            :to="{ name: 'Customers' }"
            title="Customers"
          >
            <users-icon size="1.5x" class="custom-class"></users-icon>
            <span class="d-none d-md-inline">&nbsp;Customers</span>
          </router-link>

          <router-link
            class="
              list-group-item list-group-item-action
              bg-transparent
              text-light
              border-bottom
            "
            :to="{ name: 'Groups' }"
            title="Groups"
          >
            <target-icon size="1.5x" class="custom-class"></target-icon>
            <span class="d-none d-md-inline">&nbsp;Groups</span>
          </router-link>
          <router-link
            class="
              list-group-item list-group-item-action
              bg-transparent
              text-light
            "
            :to="{ name: 'Products' }"
            title="Products"
          >
            <package-icon size="1.5x" class="custom-class"></package-icon>
            <span class="d-none d-md-inline">&nbsp;Products</span>
          </router-link>
          <router-link
            class="
              list-group-item list-group-item-action
              bg-transparent
              text-light
            "
            :to="{ name: 'ProductsSearch' }"
            title="Products Search"
          >
            <search-icon size="1.5x" class="custom-class"></search-icon>
            <span class="d-none d-md-inline">&nbsp;Products Search</span>
          </router-link>
          <router-link
            class="
              list-group-item list-group-item-action
              bg-transparent
              text-light
            "
            :to="{ name: 'Rules' }"
            title="Rules"
          >
            <file-text-icon size="1.5x" class="custom-class"></file-text-icon>
            <span class="d-none d-md-inline">&nbsp;Rules</span>
          </router-link>

          <router-link
            class="
              list-group-item list-group-item-action
              bg-transparent
              text-light
            "
            :to="{ name: 'PriceList' }"
            title="Price List"
          >
            <list-icon size="1.5x" class="custom-class"></list-icon>
            <span class="d-none d-md-inline">&nbsp;Price List</span>
          </router-link>
          <router-link
            class="
              list-group-item list-group-item-action
              bg-transparent
              text-light
              border-bottom
            "
            :to="{ name: 'EmailLists' }"
            title="Email Lists"
          >
            <mail-icon size="1.5x" class="custom-class"></mail-icon>
            <span class="d-none d-md-inline">&nbsp;Email Lists</span>
          </router-link>
          <router-link
            class="
              list-group-item list-group-item-action
              bg-transparent
              text-light
            "
            :to="{ name: 'OrdersOnline' }"
            title="Online Orders"
          >
            <monitor-icon size="1.5x" class="custom-class"></monitor-icon>
            <span class="d-none d-md-inline">&nbsp;Online Orders</span>
          </router-link>
          <router-link
            class="
              list-group-item list-group-item-action
              bg-transparent
              text-light
            "
            :to="{ name: 'OrdersApi' }"
            title="API Orders"
          >
            <code-icon size="1x" class="custom-class"></code-icon>
            <span class="d-none d-md-inline">&nbsp;API Orders</span>
          </router-link>
          <router-link
            class="
              list-group-item list-group-item-action
              bg-transparent
              text-light
            "
            :to="{ name: 'edi' }"
            title="EDI"
          >
            <server-icon size="1.5x" class="custom-class"></server-icon>
            <span class="d-none d-md-inline">&nbsp;EDI Orders</span>
          </router-link>
          <router-link
            class="
              list-group-item list-group-item-action
              bg-transparent
              text-light
              border-bottom
            "
            :to="{ name: 'OrderUpload' }"
            title="Order Upload"
          >
            <upload-icon size="1.2x" class="custom-class"></upload-icon>
            <span class="d-none d-md-inline">&nbsp;Order Upload</span>
          </router-link>

          <router-link
            class="
              list-group-item list-group-item-action
              bg-transparent
              text-light
            "
            :to="{ name: 'Scan' }"
            title="Scan"
          >
            <radio-icon size="1.5x" class="custom-class"></radio-icon>
            <span class="d-none d-md-inline">&nbsp;Scan</span>
          </router-link>
          <router-link
            class="
              list-group-item list-group-item-action
              bg-transparent
              text-light
              border-bottom
            "
            :to="{ name: 'Receipts' }"
            title="Receive"
          >
            <package-icon size="1.5x" class="custom-class"></package-icon>
            <span class="d-none d-md-inline">&nbsp;Receipts</span>
          </router-link>

          <router-link
            class="
              list-group-item list-group-item-action
              bg-transparent
              text-light
              border-bottom
            "
            :to="{ name: 'Tracking' }"
            title="Tracking"
          >
            <compass-icon size="1.5x" class="custom-class"></compass-icon>
            <span class="d-none d-md-inline">&nbsp;Tracking</span>
          </router-link>

          <router-link
            class="
              list-group-item list-group-item-action
              bg-transparent
              text-light
            "
            :to="{ name: 'Users' }"
            title="Users"
          >
            <users-icon size="1.5x" class="custom-class"></users-icon>
            <span class="d-none d-md-inline">&nbsp;Users</span>
          </router-link>
          <router-link
            class="
              list-group-item list-group-item-action
              bg-transparent
              text-light
            "
            :to="{ name: 'Docs' }"
            title="Docs"
          >
            <help-circle-icon
              size="1.5x"
              class="custom-class"
            ></help-circle-icon>
            <span class="d-none d-md-inline">&nbsp;Docs</span>
          </router-link>
          <router-link
            class="
              list-group-item list-group-item-action
              bg-transparent
              text-light
            "
            :to="{ name: 'Webhooks' }"
            title="Webhooks"
          >
            <download-cloud-icon
              size="1.5x"
              class="custom-class"
            ></download-cloud-icon>
            <span class="d-none d-md-inline">&nbsp;Webhooks</span>
          </router-link>

          <router-link
            class="
              list-group-item list-group-item-action
              bg-transparent
              text-light
            "
            :to="{ name: 'Kanban' }"
          >
            <trello-icon size="1.5x" class="custom-class"></trello-icon>
            <span class="d-none d-md-inline">&nbsp;Road Map</span>
          </router-link>
        </div>
      </div>
      <!-- /#sidebar-wrapper -->

      <!-- Page Content -->
      <div id="page-content-wrapper">
        <div class="container-fluid py-3">
          <slot />
        </div>
      </div>
      <!-- /#page-content-wrapper -->
    </div>
  </div>
</template>


<script>
// import navBar from "@/components/navBar";
import { auth } from "@/firebaseConfig.js";
import { state, actions } from "@/store";
import Spinner from "@/components/Spinner";
import { getters } from "@/store";
// import { store, state, mutations, actions } from "@/store";
import {
  SidebarIcon,
  GridIcon,
  UsersIcon,
  TargetIcon,
  FileTextIcon,
  ListIcon,
  PackageIcon,
  SearchIcon,
  MailIcon,
  DownloadCloudIcon,
  HelpCircleIcon,
  TrelloIcon,
  // FileIcon,
  CompassIcon,
  LogOutIcon,
  RadioIcon,
  CodeIcon,
  MonitorIcon,
  ServerIcon,
  UploadIcon
} from "vue-feather-icons";

// import footer from "@/components/Footer";

export default {
  name: "BasicLayout",
  components: {
    Spinner,
    SidebarIcon,
    GridIcon,
    UsersIcon,
    TargetIcon,
    FileTextIcon,
    ListIcon,
    PackageIcon,
    SearchIcon,
    MailIcon,
    DownloadCloudIcon,
    HelpCircleIcon,
    TrelloIcon,
    // FileIcon,
    CompassIcon,
    LogOutIcon,
    RadioIcon,
    CodeIcon,
    MonitorIcon,
    ServerIcon,
    UploadIcon
  },
  data() {
    return {
      toggle_menu: false
    };
  },

  methods: {
    // ...mapActions(["clearUser"]),
    logout: function() {
      auth
        .signOut()
        .then(() => {
          actions.clearUser();
          this.$router.push("/login");
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  computed: {
    // ...mapGetters(["userProfile"]),
    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return state.userProfile;
    },
    isLoading() {
      return getters.isLoading();
    }
  }
};
</script>

<style scoped>
#page-content-wrapper {
  margin-top: 80px;
}
.sticky-top {
  top: 80px;
}

#sidebar-wrapper {
  background-color: #202630;
  min-height: 100vh;
  margin-left: -5rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
}

#sidebar-wrapper .list-group {
  width: 5rem;
  font-size: 0.85rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

.sidebar {
  background-color: rgb(103, 103, 243);
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #sidebar-wrapper .list-group {
    width: 15rem;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}
</style>


