<template>
  <Receipt :receipt="receipt"></Receipt>
</template>

<script>
import Receipt from "@/components/Receipt";
export default {
  name: "receiptcreate",
  components: {
    Receipt,
  },
  data: function () {
    return {
      receipt: {},
    };
  },
};
</script>

<style>
</style>